import { NotificationDeliveryModel } from "./notification-delivery-model";
import { NotificationMessage } from "./notification-message";

export class Notification {

    id:string ='';
    to:string ='';
    cc:string ='';
    message:NotificationMessage = new NotificationMessage();
    delivery:NotificationDeliveryModel = {} as NotificationDeliveryModel;
    recordDate:Date = new Date;
    send?:boolean= true;
}
