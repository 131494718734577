import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { WorkorderService } from '../services/orders/workorder.service';
import { environment } from '../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges {

  uidActive: string = '';

  sysVersion: string = environment.version;
  sysEnviromentProd: boolean = environment.production;

  userPosition: string;
  sessionUid: string
  sessionName: string;

  company: string = 'sitrack';
  showCompany: boolean = false;

  sessionToken:string;


  constructor(public loginService: LoginService, private router: Router, private _workorderService: WorkorderService,
    private _toastrService:ToastrService, private auth:AngularFireAuth) {
    this.userPosition = localStorage.getItem(environment.sessionUserPosition)!;
    this.sessionUid = localStorage.getItem(environment.sessionID)!;
    this.sessionName = localStorage.getItem(environment.sessionActualUser)!;
    this.sessionToken = '';
  }

  async validSessionToken(){
    let sessionResult = await this.auth.idToken;
    sessionResult.subscribe(token=>{
      this.sessionToken = token!;
    });
    }

  ngOnInit(): void {
    this.company = localStorage.getItem(environment.sessionWorkCompany) ?? 'sitrack';
    this.uidActive = localStorage.getItem(environment.sessionID)!;
    this.loginService.sessionUser = localStorage.getItem(environment.sessionActualUser)!;
    this.loginService.sessionUserPosition = localStorage.getItem(environment.sessionUserPosition)!;
    this.validSessionToken();
    this._workorderService.checkActualVersion().subscribe(version => {

      const releasedVersion = version;
      if (releasedVersion.value !== environment.version) {
        this._toastrService.success('Se ha liberado una nueva versión de la plataforma, por favor actualiza tu navegador utilizando la combinación Ctrl+F5 o Ctrl+R', 'Versión ' + releasedVersion.value, {
          timeOut: 0,
        })
      }
      //Validamos contra la version actual
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.uidActive = localStorage.getItem(environment.sessionID)!;
  }

  logout() {
    localStorage.removeItem(environment.sessionID);
    localStorage.removeItem(environment.sessionActualUser);
    localStorage.removeItem(environment.sessionUserPosition);
    localStorage.removeItem(environment.sessionCustomer);
    localStorage.removeItem(environment.sessionProducts);
    this.loginService.logout().then(() => {
      this.router.navigate(['']);
    });

  }

  toggleSelectCompany() {
    this.showCompany = true;
  }

  selectCompany2Work(company: string) {
    this.company = company;
    this.showCompany = false;
    localStorage.setItem(environment.sessionWorkCompany, this.company);
    this._toastrService.info("Ajustando ambiente..., un momento por favor.","Workspace",{
      timeOut:5000
    })
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  goto(link){
    this.router.navigate([link]);
  }
}
