import { ProductInvoiceconfig } from "./product-invoiceconfig";
import { ProductPrice } from "./product-price";
import { WoProductStorehouse } from "./wo-product-storehouse";

export class Product {

    id:string ='';
    group:string = '';
    code:string ='';
    concept:string = '';
    description:string = '';
    solution:string ='';
    businessType:string= '';
    periodMonth:number= 12;

    baseAmount:number = 0;
    unitCodeSAT:string ='';

    priceList:Array<ProductPrice> = [];
    invoiceConfig?:Array<ProductInvoiceconfig> = [];

    validCash12:boolean = false;
    validFinanced12:boolean = false;
    validFinancedEquipment12:boolean = false;
    validLoan:boolean = false;
    validLoan12:boolean = false;
    validLoan24:boolean = false;
    validLoan36:boolean = false;
    
    active:boolean = true;
    addReneworder:boolean = true;

    inventory:number=0;
    inventoryUpdate:Date = new Date(0);

    //Solo para presentacion del almacen, no se requienre guardar.
    productStorehouseWO?: Array<WoProductStorehouse>;
    totalPending?:number = 0;
    totalApproved?:number = 0;

    constructor (){
      
    }

    static json2Array(jsonArray:any){
        const objectArray = new Array<Product>();
        
        jsonArray.forEach(jsonObject => {
            const object = Product.json2Object(jsonObject);
            objectArray.push(object);
        });
    
        return objectArray;
    }
    
    static json2Object(jsonObject:any){
        const object = new Product();
    
        object.id = jsonObject.id;
        object.group = jsonObject.group;
        object.code = jsonObject.code;
        object.concept = jsonObject.concept;
        object.description = jsonObject.description;
        object.solution = jsonObject.solution;
        object.businessType = jsonObject.businessType;
        object.periodMonth = jsonObject.periodMonth;
        object.active = jsonObject.active;
        object.priceList = ProductPrice.json2Array(jsonObject.priceList);
    
        return object;
    }
}
