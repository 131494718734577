import { Injectable } from "@angular/core";
import { NotificationService } from "../../services/orders/notification.service";
import { Utils } from "../../shared/utils";
import { Notification } from "../../model/notification";
import { NotificationLayout } from "../../model/notification-layout";

@Injectable({
    providedIn: 'root',
  })
export class NotificationsUsecase {

    constructor(private _notificationService:NotificationService){

    }

    async getNotificationsByCompany(company:string):Promise<Array<Notification>>{
        return new Promise(async (resolve, reject)=>{
            let result = await this._notificationService.getByCompany(company);
            result.subscribe(notifications=>{
                notifications.forEach(not=>{
                    if(not.delivery !== undefined){
                        if(not.delivery.startTime){
                            not.delivery.startTime = Utils.timestampToDate(not.delivery.startTime);
                        }
                        if(not.delivery.endTime){
                            not.delivery.endTime = Utils.timestampToDate(not.delivery.endTime);
                        }
                    }
                })
                console.log(notifications);
                return(notifications);
            })
        });
    }

    async getByCompanyAndPeriod(company:string, startPeriod:Date, endPeriod:Date):Promise<Array<Notification>>{
        return new Promise(async (resolve,reect)=>{
            let resultSubs = await this._notificationService.getByCompanyBetweenDate(company,startPeriod,endPeriod).subscribe(response=>{
                console.log(response);
                resolve(response);
                resultSubs.unsubscribe();
            });
        })
    }

    async getByCompanyAndPeriodPages(company:string, startPeriod:Date, endPeriod:Date, itemsPerPage:number, lastNotification:Notification):Promise<Array<Notification>>{
        return new Promise(async (resolve,reect)=>{
            let resultSubs = await this._notificationService.getByCompanyBetweenDatePages(company,startPeriod,endPeriod,itemsPerPage,lastNotification).subscribe(response=>{
                console.log(response);
                resolve(response);
                resultSubs.unsubscribe();
            });
        })
    }

    async getNotificationLayoutByCompany(company:string):Promise<Array<NotificationLayout>>{
        return new Promise(async (resolve,reject) =>{
            let resultSub = await this._notificationService.getNotificationLayoutByCompany(company).subscribe(response=>{
                resolve(response);
                resultSub.unsubscribe();
            })
        });
    }

    async getNotificationLayoutByCompanyPages(company:string, itemsPerPage:number, lastNotification:NotificationLayout):Promise<Array<NotificationLayout>>{
        return new Promise(async (resolve,reject) =>{
            let resultSub = await this._notificationService.getNotificationLayoutByCompanyPages(company,itemsPerPage,lastNotification).subscribe(response=>{
                resolve(response);
                resultSub.unsubscribe();
            })
        });
    }

    async getNotificationLayoutById(idLayout:string):Promise<NotificationLayout>{
        return new Promise(async (resolve,reject) =>{
            let resultSub = await this._notificationService.getNotificationLayoutById(idLayout).subscribe(response=>{
                resolve(response);
                resultSub.unsubscribe();
            })
        });
    }

    upsertNotificationLayout(layout:NotificationLayout):Promise<boolean>{
        return new Promise((resolve,reject)=>{
            this._notificationService.upsertNotificationLayout(layout).then(result=>{
                resolve(true);
            }).catch(err=>{
                reject(err);
            })
        });
    }
}
