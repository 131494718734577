import moment from "moment";

export class Utils {


    static calculateNextDate(startDate: Date, next: number, metric: string) {
        if (metric === 'D') {
            return new Date(startDate.setTime(startDate.getTime() + next * 86400000));
        } else {
            return new Date(startDate.setMonth(startDate.getMonth() + next * 1));
        }
    }

    static calculateAfterDate(startDate: Date, next: number, metric: string) {
        if (metric === 'D') {
            return new Date(startDate.setTime(startDate.getTime() - next * 86400000));
        } else {
            return new Date(startDate.setMonth(startDate.getMonth() - next * 1));
        }
    }

    static fixDate(date: Date) {
        if (date.toString().length > 10) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0);
        } else {
            const splitDate = date.toString().split('-');
            const year = parseInt(splitDate[0], 10);
            const month = parseInt(splitDate[1], 10) - 1;
            const day = parseInt(splitDate[2], 10);
            return new Date(year, month, day, 12, 0, 0);
        }
    }

    static fixStartDate(date: Date) {
        if (date.toString().length > 10) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        } else {
            const splitDate = date.toString().split('-');
            const year = parseInt(splitDate[0], 10);
            const month = parseInt(splitDate[1], 10) - 1;
            const day = parseInt(splitDate[2], 10);
            return new Date(year, month, day, 0, 0, 0);
        }
    }

    static fixEndDate(date: Date) {
        if (date.toString().length > 10) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 59, 59);
        } else {
            const splitDate = date.toString().split('-');
            const year = parseInt(splitDate[0], 10);
            const month = parseInt(splitDate[1], 10) - 1;
            const day = parseInt(splitDate[2], 10);
            return new Date(year, month, day, 11, 59, 59);
        }
    }

    static fixDotDate(date: string) {
        const splitDate = date.split('.');
        const year = parseInt(splitDate[2], 10);
        const month = parseInt(splitDate[1], 10) - 1;
        const day = parseInt(splitDate[0], 10);
        return new Date(year, month, day, 12, 0, 0);
    }

    static fixSlashDate(date: string) {
        const splitDate = date.split('/');
        const yearString = '20' + splitDate[2];
        const year = parseInt(yearString, 10);
        const month = parseInt(splitDate[1], 10) - 1;
        const day = parseInt(splitDate[0], 10);
        return new Date(year, month, day, 12, 0, 0);
    }

    static getDate(date: string) {
        const splitDate = date.split('-');
        const year = parseInt(splitDate[0], 10);
        const month = parseInt(splitDate[1], 10) - 1;
        const day = parseInt(splitDate[2], 10);
        return new Date(year, month, day, 12, 0, 0);
    }

    static getMondayOfCurrentWeek(d: any) {
        const day = d.getDay();
        return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -6 : 1) - day);
    }

    static getSundayOfCurrentWeek(d: any) {
        const day = d.getDay();
        return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? 0 : 7) - day);
    }

    static getWeekDays(d: any) {
        const day = d.getDay();
        const week = new Array<Date>();
        week.push(new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -6 : 1) - day));
        week.push(new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -5 : 2) - day));
        week.push(new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -4 : 3) - day));
        week.push(new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -3 : 4) - day));
        week.push(new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -2 : 5) - day));
        week.push(new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -1 : 6) - day));
        week.push(new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? 0 : 7) - day));
        return week;
    }

    static getWeekNumber(d: Date) {
        const onejan = new Date(d.getFullYear(), 0, 1);
        return Math.ceil((((d.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
    }

    static getDayBegin(date: any) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    }
    static getDayEnd(date: any) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    }

    static getStringDate() {
        const today = new Date();
        const year = today.getFullYear().toString();
        let month = '';
        if (today.getMonth() < 10) {
            month = '0' + (today.getMonth() + 1).toString();
        } else {
            month = (today.getMonth() + 1).toString();
        }
        let day = '';
        if (today.getDate() < 10) {
            day = '0' + today.getDate().toString();
        } else {
            day = today.getDate().toString();
        }

        return day + month + year.substring(2, year.length);
    }

    static getStringDateInverse() {
        const today = new Date();
        const year = today.getFullYear().toString();
        let month = '';
        if (today.getMonth() < 10) {
            month = '0' + (today.getMonth() + 1).toString();
        } else {
            month = (today.getMonth() + 1).toString();
        }
        let day = '';
        if (today.getDate() < 10) {
            day = '0' + today.getDate().toString();
        } else {
            day = today.getDate().toString();
        }

        return year.substring(2, year.length) + month + day;
    }

    static getMomentFormatDate(date2Format: any) {
        let validDate = this.getValidDate(date2Format);
    }

    static getFormatDate(date2Format: any, separator: string) {
        const year = date2Format.getFullYear().toString();
        let month = '';
        if (date2Format.getMonth() <= 8) {
            month = '0' + (date2Format.getMonth() + 1).toString();
        } else {
            month = (date2Format.getMonth() + 1).toString();
        }
        let day = '';
        if (date2Format.getDate() <= 8) {
            day = '0' + date2Format.getDate().toString();
        } else {
            day = date2Format.getDate().toString();
        }

        return day + separator + month + separator + year;
    }

    static getYearMonthName(date2Format: any, separator: string) {
        const year = date2Format.getFullYear().toString();
        let months = ['ENERO','FEBRERO','MARZO','ABRIL','MAYO','JUNIO','JULIO','AGOSTO','SEPTIEMBRE','OCTUBRE','NOVIEMBRE','DICIEMBRE'];
        let month = '';
        return months[date2Format.getMonth()] +separator+ year;
    }

    static getYearMonthDate(date2Format: any, separator: string) {
        const year = date2Format.getFullYear().toString();
        let month = '';
        if (date2Format.getMonth() <= 8) {
            month = '0' + (date2Format.getMonth() + 1).toString();
        } else {
            month = (date2Format.getMonth() + 1).toString();
        }
        let day = '';
        if (date2Format.getDate() <= 8) {
            day = '0' + date2Format.getDate().toString();
        } else {
            day = date2Format.getDate().toString();
        }

        return month + year;
    }

    static getInverseFormatDate(date2Format: any, separator: string) {
        const year = date2Format.getFullYear().toString();
        let month = '';
        if (date2Format.getMonth() < 10) {
            month = '0' + (date2Format.getMonth() + 1).toString();
        } else {
            month = (date2Format.getMonth() + 1).toString();
        }
        let day = '';
        if (date2Format.getDate() < 10) {
            day = '0' + date2Format.getDate().toString();
        } else {
            day = date2Format.getDate().toString();
        }
        console.log(year + separator + month + separator + day);
        return year + separator + month + separator + day;
    }


    static getFormatTimestamp(date2Format: any) {
        const year = date2Format.getFullYear().toString();
        let month = '';
        if (date2Format.getMonth() < 10) {
            month = '0' + (date2Format.getMonth() + 1).toString();
        } else {
            month = (date2Format.getMonth() + 1).toString();
        }
        let day = '';
        if (date2Format.getDate() < 10) {
            day = '0' + date2Format.getDate().toString();
        } else {
            day = date2Format.getDate().toString();
        }

        return day + '/' + month + '/' + year + ' ' + date2Format.getHours() + ':' + date2Format.getMinutes();
    }

    static getFormalDate() {
        const today = new Date();
        const year = today.getFullYear().toString();
        let month = '';
        if (today.getMonth() < 10) {
            month = '0' + (today.getMonth() + 1).toString();
        } else {
            month = (today.getMonth() + 1).toString();
        }
        let day = '';
        if (today.getDate() < 10) {
            day = '0' + today.getDate().toString();
        } else {
            day = today.getDate().toString();
        }

        console.log(year.substring(2, year.length) + month + day);
        return day + ' / ' + month + ' / ' + year;
    }

    static getCalendarDate(today: Date) {
        const year = today.getFullYear().toString();
        let month = '';
        if (today.getMonth() < 10) {
            month = '0' + (today.getMonth() + 1).toString();
        } else {
            month = (today.getMonth() + 1).toString();
        }
        let day = '';
        if (today.getDate() < 10) {
            day = '0' + today.getDate().toString();
        } else {
            day = today.getDate().toString();
        }

        return year + '-' + month + '-' + year + 'T' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    }

    static getDaysBetweenDays(startDate: Date, stopDate: Date) {
        const arr = new Array();
        const dt = new Date(startDate);
        while (dt <= stopDate) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    }

    static getFirstDayActualMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    static getLastDayActualMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    static getDateFromShort(shortDate: any) {
        return new Date(shortDate);
    }

    static timestampToDate(date2Change: any) {
        const timeStemp = date2Change as firebase.default.firestore.Timestamp;
        const date = timeStemp.toDate();
        return date;
    }

    static currencyToNumber(currencyString: any) {
        let currency = '0';
        if (currencyString) {
            if (!currencyString.includes('$-')) {
                currency = currencyString.trim().replace('$', '').replace(',', '');
            }
        }
        return Number.parseFloat(currency);
    }

    static fixDateWithTime(date: Date, time: string) {
        /** obtiene horas y minutos */
        const timeArray = time.split(':');
        const hours = Number.parseInt(timeArray[0]);
        const minutes = Number.parseInt(timeArray[1]);

        if (date.toString().length > 10) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0);
        } else {
            const splitDate = date.toString().split('-');
            const year = parseInt(splitDate[0], 10);
            const month = parseInt(splitDate[1], 10) - 1;
            const day = parseInt(splitDate[2], 10);
            return new Date(year, month, day, hours, minutes, 0);
        }
    }

    static addMinutes(date: any, minutes: any) {
        return new Date(date.getTime() + minutes * 60000);
    }

    static zeroPad(num: any, numZeros: any) {
        const n = Math.abs(num);
        const zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
        let zeroString = Math.pow(10, zeros).toString().substr(1);
        if (num < 0) {
            zeroString = '-' + zeroString;
        }

        return zeroString + n;
    }

    static formatNumber(num: any) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    static imageToB64(urlFile: any) {
        const reader = new FileReader();
        reader.readAsDataURL(urlFile);
        return reader.result;
    }

    static getTime(date2Get: Date) {
        const minute = date2Get.getMinutes();
        let minString = '';
        if (minute < 10) {
            minString = '0' + minute;
        } else {
            minString = minute.toString();
        }
        return date2Get.getHours() + ':' + minString;
    }

    static getFormatDateTime(date2Format: any, separator: string) {
        const year = date2Format.getFullYear().toString();
        let month = '';
        if (date2Format.getMonth() < 10) {
            month = '0' + (date2Format.getMonth() + 1).toString();
        } else {
            month = (date2Format.getMonth() + 1).toString();
        }
        let day = '';
        if (date2Format.getDate() < 10) {
            day = '0' + date2Format.getDate().toString();
        } else {
            day = date2Format.getDate().toString();
        }
        let hours = '';
        if (date2Format.getHours() < 10) {
            hours = '0' + date2Format.getHours().toString();
        } else {
            hours = date2Format.getHours().toString();
        }
        let minutes = '';
        if (date2Format.getMinutes() < 10) {
            minutes = '0' + date2Format.getMinutes().toString();
        } else {
            minutes = date2Format.getMinutes().toString();
        }

        return day + '/' + month + '/' + year + ' ' + hours + ":" + minutes;
    }

    static getFormatFullDateTime(date2Format: Date) {
        const year = date2Format.getFullYear().toString();
        let month = '';
        if (date2Format.getMonth() < 9) {
            month = '0' + (date2Format.getMonth() + 1).toString();
        } else {
            month = (date2Format.getMonth() + 1).toString();
        }
        let day = '';
        if (date2Format.getDate() < 9) {
            day = '0' + date2Format.getDate().toString();
        } else {
            day = date2Format.getDate().toString();
        }
        let hours = '';
        if (date2Format.getHours() === 0) {
            date2Format.setHours(new Date().getHours());
        }
        if (date2Format.getHours() < 10) {
            hours = '0' + date2Format.getHours().toString();
        } else {
            hours = date2Format.getHours().toString();
        }
        let minutes = '';
        if (date2Format.getMinutes() === 0) {
            date2Format.setMinutes(new Date().getMinutes());
        }
        if (date2Format.getMinutes() < 10) {
            minutes = '0' + date2Format.getMinutes().toString();
        } else {
            minutes = date2Format.getMinutes().toString();
        }

        return year + '-' + month + '-' + day + 'T' + hours + ":" + minutes + ':00';
    }


    static zfill(number, width) {
        var numberOutput = Math.abs(number); /* Valor absoluto del número */
        var length = number.toString().length; /* Largo del número */
        var zero = "0"; /* String de cero */

        if (width <= length) {
            if (number < 0) {
                return ("-" + numberOutput.toString());
            } else {
                return numberOutput.toString();
            }
        } else {
            if (number < 0) {
                return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
            } else {
                return ((zero.repeat(width - length)) + numberOutput.toString());
            }
        }
    }

    static getValidDate(dete2Valid: string) {
        if (moment(dete2Valid, 'DD/MM/YYYY').isValid()) {
            return moment(dete2Valid, 'DD/MM/YYYY').toDate();
        }
        if (moment(dete2Valid, 'MM/DD/YYYY').isValid()) {
            return moment(dete2Valid, 'MM/DD/YYYY').toDate();
        }
        if (moment(dete2Valid, 'DD/MM/YY').isValid()) {
            return moment(dete2Valid, 'DD/MM/YY').toDate();
        }
        if (moment(dete2Valid, 'MM/DD/YY').isValid()) {
            return moment(dete2Valid, 'MM/DD/YY').toDate();
        }
        if (moment(dete2Valid, 'D/M/YY').isValid()) {
            return moment(dete2Valid, 'D/M/YY').toDate();
        }
        if (moment(dete2Valid, 'M/D/YY').isValid()) {
            return moment(dete2Valid, 'M/D/YY').toDate();
        }
        if (moment(dete2Valid, 'DD/M/YY').isValid()) {
            return moment(dete2Valid, 'D/M/YY').toDate();
        }
        if (moment(dete2Valid, 'M/DD/YY').isValid()) {
            return moment(dete2Valid, 'M/D/YY').toDate();
        }
        if (moment(dete2Valid, 'D/MM/YY').isValid()) {
            return moment(dete2Valid, 'D/M/YY').toDate();
        }
        if (moment(dete2Valid, 'MM/D/YY').isValid()) {
            return moment(dete2Valid, 'M/D/YY').toDate();
        }
        if (moment(dete2Valid, 'D/M/YYYY').isValid()) {
            return moment(dete2Valid, 'D/M/YYYY').toDate();
        }
        if (moment(dete2Valid, 'M/D/YYYY').isValid()) {
            return moment(dete2Valid, 'M/D/YYYY').toDate();
        }
        if (moment(dete2Valid, 'DD/M/YYYY').isValid()) {
            return moment(dete2Valid, 'D/M/YYYY').toDate();
        }
        if (moment(dete2Valid, 'M/DD/YYYY').isValid()) {
            return moment(dete2Valid, 'M/D/YYYY').toDate();
        }
        if (moment(dete2Valid, 'D/MM/YYYY').isValid()) {
            return moment(dete2Valid, 'D/M/YYYY').toDate();
        }
        if (moment(dete2Valid, 'MM/D/YYYY').isValid()) {
            return moment(dete2Valid, 'M/D/YYYY').toDate();
        }
        return new Date();
    }
}
