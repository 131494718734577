<!-- Modal -->
<div class="modal fade" id="notificationsSendedModal" tabindex="-1" aria-labelledby="notificationsSendedModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="notificationsSendedModalLabel">Notificaciones enviadas.</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" >
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-6">
                
              </div>
              <div class="col-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Periodo</span>
                  <input type="date" class="form-control" [ngModel]="startPeriod | date:'yyyy-MM-dd'" (ngModelChange)="startPeriod = $event">
                  <input type="date" class="form-control" [ngModel]="endPeriod | date:'yyyy-MM-dd'" (ngModelChange)="endPeriod = $event">
                  <button class="btn btn-outline-primary" (click)="getByCompany()" >
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col" style="height: 500px; overflow: auto;">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th style="width: 10%;">Registrado</th>
                                <th style="width: 30%;">Destinatarios</th>
                                <th style="width: 40%;">Mensaje</th>
                                <th style="width: 10%;">Entrega</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="loading()">
                            <td colspan="4" class="text-center">
                              <h1>
                                <div class="spinner-border text-warning" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </h1>
                              <h5 class="mb-0">Cargando notificaciones.</h5>
                            </td>
                          </tr>
                           <tr *ngFor="let note of notifications">
                            <td>
                              <strong>{{note.recordDate | date:'dd/MM/yyyy'}}</strong>
                              <p class="mb-0">{{note.recordDate | date:'HH:mm'}}</p>
                            </td>
                            <td>
                              <div class="input-group">
                                <textarea class="form-control" [ngModel]="note.to"></textarea>
                              </div>
                             
                            </td>
                            <td class="collapse-container">
                              <div class="input-group">
                                <span class="input-group-text" id="basic-addon1">Periodo</span>
                                <input type="text" class="form-control" [ngModel]="note.message.subject" disabled>
                                <button class="btn btn-outline-secondary" type="button"  data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-preview-'+note.id">Ver Contenido</button>
                              </div>
                              <div class="collapse collapse-html-preview" [id]="'collapse-preview-'+note.id">
                                <div class="card card-body shadow">
                                  <button type="button" class="btn-close" aria-label="Close" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-preview-'+note.id"></button>
                                  <iframe [srcdoc]="note.message.html" frameborder="0"></iframe>
                                </div>
                              </div>
                            </td>
                            <td>
                              <strong>{{note.delivery?.state}}</strong>
                              <p>{{note.delivery?.error}}</p>
                            </td>
                           </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!--button type="button" class="btn btn-link" (click)="loadMore()">Cargar mas</button-->
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>