import { Invoice } from "./invoice";
import { InvoiceDetail } from "./invoice-detail";
import { ProductPortioninvoice } from "./product-portioninvoice";
import { Workorder } from "./workorder";
import { VinBreakdown } from "./workorder/vin-breakdown";

export class PaymentWorkorder {

    id!:string;
    workorderId?:string;
    vin:string ='';
    
    receiptNumber:string ='1/N';
    receipt:number =1;
    percentageEquipment:number = 0;
    paymentEquipment:number = 0;
    percentageInstall:number = 0;
    paymentInstall:number = 0;
    percentageActivation:number = 0;
    paymentActivation:number = 0;
    percentageAnnualPay:number = 0;
    paymentAnnualPay:number = 0;

    additionals:number = 0;

    total:number = 0;
    totalTaxes:number = 0;
    payDate:Date = new Date();
    emitInvoiceDate?:Date = new Date();
    payitInvoceDate?:Date = new Date();
    first:boolean = false;
    status:string='PENDIENTE';
    invoice:number = 0;
    invoiceRelated:Array<PaymentInvoiceRelated> = [];
    numUnitsWithInvoice:number =0;

    breakdown:Array<ProductPortioninvoice> = [];
    detailsTemp:Array<InvoiceDetail> = [];

    workorder?:Workorder;
    invoiceObj?:Invoice;

    uuidInvoice:string = '';
    pathXml:string = '';
    pathPdf:string = '';

    productConcept:string = '';
    vinBreakdown:Array<VinBreakdown> = [];
}


export class PaymentInvoiceRelated{
    id!:string;
    workorderId:string ='';
    paymentId:string = '';
    invoiceId:string='';
    serie:string ='';
    folio:number =0;

    payment?:PaymentWorkorder;
    invoice?:Invoice;
    workorder?:Workorder;
}