export class ProductPrice {

    id?:string;

    businessType:string ='';
    periodMonth:number = 12;

    equipmentMin:number = 0;
    equipmentMax:number=0;

    installMin:number = 0;
    installMax:number=0;

    activationMin:number = 0;
    activationMax:number=0;

    annualSubscriptionMin:number = 0;
    annualSubscriptionMax:number=0;

    monthlySubscriptionMin:number = 0;
    monthlySubscriptionMax:number=0;

    codeSAT:string ='';

    totalMin:number = 0;
    totalMax:number = 0;
    
    static json2Array(jsonArray:any){
        const objectArray = new Array<ProductPrice>();
        
        jsonArray.forEach(jsonObject => {
            const object = ProductPrice.json2Object(jsonObject);
            objectArray.push(object);
        });
    
        return objectArray;
    }
    
    static json2Object(jsonObject:any){
        const object = new ProductPrice();
    
        object.id = jsonObject.id;
        object.businessType = jsonObject.businessType;
        object.periodMonth = jsonObject.periodMonth;
        object.equipmentMin = jsonObject.equipmentMin;
        object.equipmentMax = jsonObject.equipmentMax;
        object.installMin = jsonObject.installMin;
        object.installMax = jsonObject.installMax;
        object.annualSubscriptionMin = jsonObject.annualSubscriptionMin;
        object.annualSubscriptionMax = jsonObject.annualSubscriptionMax;
        object.monthlySubscriptionMin = jsonObject.monthlySubscriptionMin;
        object.monthlySubscriptionMax = jsonObject.monthlySubscriptionMax;
        object.totalMin = jsonObject.totalMin;
        object.totalMax = jsonObject.totalMax;
    
        return object;
    }
}
