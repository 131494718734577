import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Notification } from '../../model/notification';
import { map } from 'rxjs/operators';
import { Utils } from '../../shared/utils';
import { NotificationLayout } from '../../model/notification-layout';
import { ref } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationCollection:string ='wo-notifications';

  notificationLayoutsCollection:string ='wo-notifications-layouts';

  constructor(private af:AngularFirestore) { }

  getByCompany(company:string){
    let collectionToSend = `${this.notificationCollection}-${company}`;
    console.log(collectionToSend);
    return this.af.collection<Notification>(collectionToSend).valueChanges();
  }

  getByCompanyBetweenDate(company:string,startPeriod:Date, endPeriod:Date){
    let collectionToSend = `${this.notificationCollection}-${company}`;
    console.log(collectionToSend);
    return this.af.collection<Notification>(collectionToSend, 
      ref=>ref.orderBy('recordDate', 'asc').startAfter(startPeriod).endBefore(endPeriod))
      .snapshotChanges().pipe(
        map(actions=>actions.map(a=>{
          const data = a.payload.doc.data() as Notification;
          if(data.recordDate){
            data.recordDate = Utils.timestampToDate(data.recordDate);
          }
          data.id = a.payload.doc.id;
          return {...data};
        }))
      );
  }

  getByCompanyBetweenDatePages(company:string,startPeriod:Date, endPeriod:Date,itemsPerPage:number, lastNotification:Notification){
    let collectionToSend = `${this.notificationCollection}-${company}`;
    console.log(collectionToSend);
    let reference:AngularFirestoreCollection<Notification>;

    if(lastNotification.id !== ''){
      reference = this.af.collection<Notification>(collectionToSend, 
        ref=>ref.orderBy('recordDate', 'asc')
        .startAfter(startPeriod).endBefore(endPeriod).startAfter(lastNotification.id).limit(itemsPerPage));
    } else{
      reference = this.af.collection<Notification>(collectionToSend, 
        ref=>ref.orderBy('recordDate', 'asc')
        .startAfter(startPeriod).endBefore(endPeriod).limit(itemsPerPage));
    }

    return reference.snapshotChanges().pipe(
        map(actions=>actions.map(a=>{
          const data = a.payload.doc.data() as Notification;
          if(data.recordDate){
            data.recordDate = Utils.timestampToDate(data.recordDate);
          }
          data.id = a.payload.doc.id;
          return {...data};
        }))
      );
  }

  sendNotification(notification:Notification, company:string){
    notification.id = this.af.createId();
    console.log(notification);
    let collectionToSend = `${this.notificationCollection}-${company}`
    return this.af.collection(collectionToSend).doc(notification.id).set({
      'to':notification.to,
      'cc':notification.cc,
      'message':{
        'subject':notification.message.subject,
        'html':notification.message.html,
      },
      'recordDate':new Date()
    },{merge:true});
  }

  upsertNotificationLayout(layout:NotificationLayout){
    if(!layout.id){
      layout.id = this.af.createId();
    }
    return this.af.collection(this.notificationLayoutsCollection).doc(layout.id).set({
      titleNotification:layout.titleNotification,
      subjectNotification:layout.subjectNotification,
      bodyNotification:layout.bodyNotification,
      company:layout.company,
      recordDate:layout.recordDate,
      idEmployeeRecord:layout.idEmployeeRecord,
      nameEmployeeRecord:layout.nameEmployeeRecord,
    },{merge:true});
  }

  getNotificationLayoutById(idLayout:string){
    return this.af.collection<NotificationLayout>(this.notificationLayoutsCollection)
    .doc(idLayout).snapshotChanges().pipe(
      map(action=>{
        const data = action.payload.data() as NotificationLayout;
        data.recordDate = Utils.timestampToDate(data.recordDate);
        data.id = action.payload.id;
        return {...data};
      })
    )
  }

  getNotificationLayoutByCompany(company:string){
    return this.af.collection<NotificationLayout>(this.notificationLayoutsCollection,
      ref=>ref.where('company','==',company).orderBy('recordDate','desc'))
      .snapshotChanges().pipe(
        map(actions=>actions.map(a=>{
          const data = a.payload.doc.data() as NotificationLayout;
          data.recordDate = Utils.timestampToDate(data.recordDate);
          data.id = a.payload.doc.id;
          return {...data};
        }))
      )
  }

  getNotificationLayoutByCompanyPages(company:string, itemPerPage:number, lastNotification:NotificationLayout){
  let reference:AngularFirestoreCollection<NotificationLayout>;
    if(lastNotification){
      reference = this.af.collection<NotificationLayout>(this.notificationLayoutsCollection,
        ref=>ref.where('company','==',company).orderBy('recordDate','desc').startAfter(lastNotification).limit(itemPerPage));
    } else {
      reference = this.af.collection<NotificationLayout>(this.notificationLayoutsCollection,
        ref=>ref.where('company','==',company).orderBy('recordDate','desc').limit(itemPerPage));
    }
    
    return reference.snapshotChanges().pipe(
        map(actions=>actions.map(a=>{
          const data = a.payload.doc.data() as NotificationLayout;
          data.recordDate = Utils.timestampToDate(data.recordDate);
          data.id = a.payload.doc.id;
          return {...data};
        }))
      )
  }
}
