<app-menu></app-menu>
<div class="container mt-5">
    <div class="row mb-3">
        <div class="col">
            <div class="card card-body">
                <div class="container-fluid p-0">
                    <div class="row">
                        <div class="col">
                            <h5>Plantillas de Notificaciones de Correo</h5>
                        </div>
                        <div class="col-3 text-end">
                            <button type="button" class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#modalNotificationLayout">Nueva</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card card-body overFlow" [ngStyle]="{'height':screenHeight-160+'px'}">
                <div class="container-fluid p-0">
                    <div class="row">
                        <div class="col-4" *ngFor="let layout of layouts">
                            <div class="card">
                                <div class="card-body">
                                    <span class="lbl">Asunto:</span>
                                    <h6 >{{layout.subjectNotification}}</h6>
                                    <span class="lbl">Cuerpo del mensaje</span>
                                    <div class="overFlow" [innerHTML]="layout.bodyNotification"></div>
                                </div>
                                <div class="card-footer d-flex justify-content-between align-items-center">
                                    <span>{{layout.titleNotification}}</span>
                                    <span>{{layout.recordDate | date:'dd/MM/yyyy'}}</span>
                                    <div class="btn-group">
                                        <a class="btn btn-primary active"  data-bs-toggle="modal" data-bs-target="#modalNotificationLayout" aria-current="page" (click)="edit(layout)" ><i class="bi bi-pencil"></i></a>
                                        <a class="btn btn-outline-secondary"><i class="bi bi-trash"></i></a>
                                      </div>
                                </div>
                            </div>
                            <!--table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Plantilla</th>
                                        <th>Asunto del correo</th>
                                        <th>Fecha Registro</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="layouts.length === 0">
                                        <td colspan="4" class="text-center">
                                            <h4 class="mb-0">No hay plantillas registradas.</h4>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let layout of layouts">
                                        <td>{{layout.titleNotification}}</td>
                                        <td>{{layout.subjectNotification}}</td>
                                        <td>{{layout.recordDate | date:'dd/MM/yyyy'}}</td>
                                        <td>
                                            <button type="button" class="btn btn-primary" (click)="edit(layout)" data-bs-toggle="modal" data-bs-target="#modalNotificationLayout">Editar</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de la edicion de la plantilla -->
<div class="modal fade" id="modalNotificationLayout" tabindex="-1" aria-labelledby="modalNotificationLayoutLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="modalNotificationLayoutLabel">Plantilla de Correo</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cleanLayout()"></button>
        </div>
        <div class="modal-body">
           <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1">Titulo de la plantilla</span>
                        <input type="text" class="form-control" [(ngModel)]="layout2Edit.titleNotification">
                      </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1">Asunto de la plantilla</span>
                        <input type="text" class="form-control" [(ngModel)]="layout2Edit.subjectNotification">
                      </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5>Contenido del Correo</h5>
                    <p>Utilize los valores dinamicos para reemplazar contenido, [[businessName]], [[links]]</p>
                    <st-editor [(ngModel)]="layout2Edit.bodyNotification" [config]="config"></st-editor>
                </div>
            </div>
           </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="upsertNotificationLayout()">Guardar</button>
          <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" #closeModalNotificationLayout (click)="cleanLayout()">Cancelar</button>
        </div>
      </div>
    </div>
  </div>