export class PaymentType {

    id:string;
    description:string;
    totalReceipts:number;
    period:number;


    constructor(id:string, description:string, totalReceipts:number, period:number){
        this.id = id;
        this.description = description;
        this.totalReceipts = totalReceipts;
        this.period = period;
    }

}
