import { Injectable,  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Client } from '../../model/client';
import { environment } from '../../../environments/environment';
import { Utils } from '../../shared/utils';
import { ClientGroup } from '../../model/client-group';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  clients: Array<Client> = [];

  clientsCollectionName = 'clients';
  clientGroupCollectionName = 'client-group';

  constructor(private afs: AngularFirestore, private http: HttpClient,) { }

  getUid() {
    return this.afs.createId();
  }

  getClientsAllFS() {
    return this.afs.collection<Client>(this.clientsCollectionName, ref => ref.orderBy('rfc', 'asc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Client;
        data.id = a.payload.doc.id;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getClientsFS() {
    return this.afs.collection<Client>(this.clientsCollectionName, ref => ref.where('active', '==', true).orderBy('rfc', 'asc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Client;
        data.id = a.payload.doc.id;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getClientById(clientId: string) {
    return this.afs
      .collection<Client>(this.clientsCollectionName)
      .doc(clientId)
      .snapshotChanges()
      .pipe(
        map(a => {
          const data = a.payload.data() as Client;
          data.id = a.payload.id;
          return { ...data };
        })
      );
  }

  getClientByIdValue(clientId: string) {
    return this.afs
      .collection<Client>(this.clientsCollectionName)
      .doc(clientId)
      .valueChanges();
  }

  upsertFS(client: Client) {
    if (!client.id) {
      client.id = this.afs.createId();
    }
    return this.afs.collection(this.clientsCollectionName).doc(client.id).set({
      clientGroupId: client.clientGroupId ? client.clientGroupId : '',
      idPlatform: client.idPlatform ? client.idPlatform : 0,
      rfc: client.rfc,
      businessName: client.businessName,
      address: client.address,
      intNumber: client.intNumber ? client.intNumber : '',
      extNumber: client.extNumber ? client.extNumber : '',
      square: client.square ? client.square : '',
      city: client.city ? client.city : '',
      state: client.state ? client.state : '',
      country: client.country ? client.country : 'MÉXICO',
      cp: client.cp ? client.cp : null,
      mainEmailContact: client.mainEmailContact ? client.mainEmailContact : null,
      contactAdminName: client.contactAdminName ? client.contactAdminName : null,
      contactAdminEmail: client.contactAdminEmail ? client.contactAdminEmail : null,
      contactAdminPhone: client.contactAdminPhone ? client.contactAdminPhone : null,
      contactOperativeName: client.contactOperativeName ? client.contactOperativeName : null,
      contactOperativeEmail: client.contactOperativeEmail ? client.contactOperativeEmail : null,
      contactOperativePhone: client.contactOperativePhone ? client.contactOperativePhone : null,
      idSaleForce: client.idSaleForce ? client.idSaleForce : null,
      cveSAE: client.cveSAE ? client.cveSAE : null,
      ctaContable: client.ctaContable ? client.ctaContable : null,
      taxSystem: client.taxSystem ? client.taxSystem : null,
      cfdiUsage: client.cfdiUsage ? client.cfdiUsage : null,
      payForm: client.payForm ? client.payForm : null,
      payMethod: client.payMethod ? client.payMethod : null,
      daysCreditPeriod: client.daysCreditPeriod ? client.daysCreditPeriod : 0,
      standarInvoice: client.standarInvoice ? client.standarInvoice : false,
      active: client.active,
      isRecurrent: client.isRecurrent ? client.isRecurrent : null,
      defaultProfile: client.defaultProfile ? client.defaultProfile : false,
      deactiveByDueValidity: client.deactiveByDueValidity ? client.deactiveByDueValidity : false,
      isInsuranceCarrier: client.isInsuranceCarrier ? client.isInsuranceCarrier : false,
      isNewRecord: client.isNewRecord ? client.isNewRecord : false,
      validSAT: client.validSAT ? client.validSAT : false,
      accountBalanceInvoice: client.accountBalanceInvoice ? client.accountBalanceInvoice : 0,
      accountBalancePayed: client.accountBalancePayed ? client.accountBalancePayed : 0,
      accountBalancePending: client.accountBalancePending ? client.accountBalancePending : 0,
      recordDate: client.recordDate ? client.recordDate : new Date(),
      automaticInvoiceBlock: client.automaticInvoiceBlock ?? 1,
      employeeCxCId:client.employeeCxCId??'',
      employeeCxCName:client.employeeCxCName??'Sin Asignar',
      payExpiredMonth:client.payExpiredMonth??false
    }, { merge: true });
  }

  upsertIDPlataformaFS(client: Client) {
    return this.afs.collection(this.clientsCollectionName).doc(client.id).set({
      idPlatform: client.idPlatform ? client.idPlatform : 0,
    }, { merge: true });
  }

  getClients() {
    this.clients = Client.json2Array(JSON.parse(localStorage.getItem(environment.sessionCustomer)!));
    return this.clients;
  }

  getClientByRFC(rfc: string) {
    console.log(rfc);
    this.getClients();
    for (let index = 0; index < this.clients.length; index++) {
      const client = this.clients[index];
      if (client.rfc === rfc) {
        return client;
      }
    }
    return undefined;
  }

  getClientByRFCFS(rfc: string) {
    return this.afs.collection<Client>(this.clientsCollectionName, ref => ref.where('rfc', '==', rfc)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Client;
        data.id = a.payload.doc.id;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        if (data.recordDate) {
          data.recordDate = Utils.timestampToDate(data.recordDate);
        }
        return { ...data };
      }))
    );
  }

  getClientByCveSAE(cveSAE: string) {
    return this.afs.collection<Client>(this.clientsCollectionName, ref => ref.where('cveSAE', '==', cveSAE)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Client;
        data.id = a.payload.doc.id;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        if (data.recordDate) {
          data.recordDate = Utils.timestampToDate(data.recordDate);
        }
        return { ...data };
      }))
    );
  }

  getClientsContainsRFC(rfc:string){
    return this.afs.collection<Client>(this.clientsCollectionName, ref => ref.where('rfc', '>=',rfc).where('rfc', '<=', rfc+"\uf8ff").where('active','==',true)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Client;
        data.id = a.payload.doc.id;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        if (data.recordDate) {
          data.recordDate = Utils.timestampToDate(data.recordDate);
        }
        return { ...data };
      }))
    );
  }

  getClientsContainsBusinessName(businessName:string){
    return this.afs.collection<Client>(this.clientsCollectionName, ref => ref.where('businessName', '>=', businessName).where('businessName', '<=', businessName+ "\uf8ff").where('active','==',true)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Client;
        data.id = a.payload.doc.id;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        if (data.recordDate) {
          data.recordDate = Utils.timestampToDate(data.recordDate);
        }
        return { ...data };
      }))
    );
  }

  upsert(client: Client) {

    if (!client.id) {
      //Si no tienen identificador es un nuevo registro
      console.log('Inserta Registro');

      this.clients.push(client);
      localStorage.setItem(environment.sessionCustomer, JSON.stringify(this.clients));
    } else {
      // Si tiene identificador se realiza una actualización.
      console.log('Actualiza Registro');
      for (let index = 0; index < this.clients.length; index++) {
        if (this.clients[index].id === client.id) {
          this.clients[index] = client;
        }
      }
      localStorage.setItem(environment.sessionCustomer, JSON.stringify(this.clients));
    }
  }

  deleteClient(clientId: string) {
    this.afs.collection(this.clientsCollectionName).doc(clientId).delete();
  }

  deactivateClient(clientId: string) {
    return this.afs.collection(this.clientsCollectionName).doc(clientId).set({
      active: false,
    }, { merge: true });
  }

  loadMasiveClients(clients: Array<Client>) {
    const url = 'https://firebasestorage.googleapis.com/v0/b/web-sitrack-ot.appspot.com/o/clients%2FCatalogo%20RFC%20SITRACK.xlsx?alt=media&token=d168b29d-19f6-45b0-8167-79a18fdade83';
    //validamos que los clientes ya esten o no registrados.
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe(result => {
      const data = new Uint8Array(result);
      const workbook = XLSX.read(data, { type: 'array' });
      // console.log(workbook);

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonParseWorkbookSheet: object[] = XLSX.utils.sheet_to_json(sheet);
      console.log(jsonParseWorkbookSheet[0]);

      jsonParseWorkbookSheet.forEach(jsonObject => {
        const client2load = new Client();

        client2load.active = true;
        client2load.rfc = jsonObject['RFC'];
        client2load.businessName = jsonObject['RAZON SOCIAL'];

        //Separamos la colonia del campo
        let splited = [];
        if (jsonObject['DOM FISCAL: COLONIA, CP']) {
          if (jsonObject['DOM FISCAL: COLONIA, CP'].includes(', CP')) {
            splited = jsonObject['DOM FISCAL: COLONIA, CP'].split(', CP ');
          }
          else if (jsonObject['DOM FISCAL: COLONIA, CP'].includes(',')) {
            splited = jsonObject['DOM FISCAL: COLONIA, CP'].split(',');
          }
          else {
            splited = jsonObject['DOM FISCAL: COLONIA, CP'].split('CP ');
          }
        }
        //console.log(splited);

        client2load.address = jsonObject['DOM FISCAL: CALLE, NUMERO'] + ', ' + splited[0] + ', ' + jsonObject['DOM FISCAL: DELGACION/MUNICIPIO, ESTADO'];
        client2load.cp = splited[1];
        client2load.contactAdminEmail = jsonObject['CONTACTO ADMON: CORREO'];
        client2load.contactAdminPhone = jsonObject['CONTACTO ADMON: TELEFONO'];
        client2load.idSaleForce = jsonObject['ID_SALES FORCE'];
        client2load.cveSAE = jsonObject['CVE_SAE'];
        client2load.ctaContable = jsonObject['CVE_SAE'];

        console.log(client2load);
        this.upsertFS(client2load);
        /* const client = this.existClient(clients,client2load.rfc);
         console.log(client);
         if(client){
            client.idSaleForce = client2load.idSaleForce;
            client.cveSAE = client2load.cveSAE;
            client.ctaContable = client2load.ctaContable;
            this.upsertFS(client);
         } else {
          console.log(client2load);
          if(client2load.id===''){
            this.upsertFS(client2load);
          }
         }*/
      });

    });
  }

  /**Actualizamos los datos fiscales que fueron validados al realizar el timbrado de una factura. */
  upsertClientSATInfo(client: Client) {
    console.log(client);
    return this.afs.collection(this.clientGroupCollectionName).doc(client.id).set({
      cp: client.cp,
      taxSystem: client.taxSystem,
      cfdiUsage: client.cfdiUsage,
      payForm: client.payForm,
      payMethod: client.payMethod,
      validSAT: client.validSAT,
    }, { merge: true });
  }

  updateClientAddressSAE(clients: Array<Client>) {
    const url = "https://firebasestorage.googleapis.com/v0/b/uat-web-sitrack-ot.appspot.com/o/cdn%2FListadoCompletoClientes-11_05_2023_export_1683832874376%20(2).xlsx?alt=media&token=12e04f0f-a80f-4689-8496-2d12f29d9a39";
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe(result => {
      const data = new Uint8Array(result);
      const workbook = XLSX.read(data, { type: 'array' });
      // console.log(workbook);

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonParseWorkbookSheet: object[] = XLSX.utils.sheet_to_json(sheet);
      console.log(jsonParseWorkbookSheet.length);

      jsonParseWorkbookSheet.forEach(jsonObject => {

        const rfc = jsonObject['RFC'].trim();
        console.log(jsonObject);

        if (rfc) {
          let client = this.existClient(clients, rfc);

          // //Si no tiene informacion del cliente genera con la informacion
          // if(!client.id){
          //   console.log('NUEVO: '+rfc);
          //   client = new Client();
          //   client.rfc = jsonObject['R.F.C. '];
          //   client.mainEmailContact = jsonObject['Mail predeterminado '];
          //   client.businessName = jsonObject['Nombre ']?jsonObject['Nombre ']:'';
          //   client.cveSAE = jsonObject['Clave ']?jsonObject['Clave ']:'';
          //   client.ctaContable = jsonObject['Cuenta contable ']?jsonObject['Cuenta contable ']:'';
          //   client.address = jsonObject['Calle ']? jsonObject['Calle ']:'';
          //   client.intNumber = jsonObject['Número interior ']? jsonObject['Número interior ']:'';
          //   client.extNumber = jsonObject['Número exterior ']?jsonObject['Número exterior ']:'';
          //   client.square = jsonObject['Colonia ']?jsonObject['Colonia ']:'';
          //   client.city = jsonObject['Municipio ']?jsonObject['Municipio ']:'';
          //   client.state = jsonObject['Estado ']?jsonObject['Estado ']:'';
          //   client.country = jsonObject['País ']?jsonObject['País ']:'';
          //   client.cp = jsonObject['Código postal ']? jsonObject['Código postal ']:'';
          //   client.taxSystem = jsonObject['Régimen fiscal ']?Utils.zfill(jsonObject['Régimen fiscal '],3):'';  
          //   client.payForm = jsonObject['Forma de pago SAT ']?Utils.zfill(jsonObject['Forma de pago SAT '],3):'';
          //   client.validSAT = jsonObject['ValidoSAT ']=== 1? true:false;

          if (client.id && this.validPlatformID(client)) {
            client.idPlatform = jsonObject['ID PLATAFORMA'] ? jsonObject['ID PLATAFORMA'] : 0;
            console.log(jsonObject['ID PLATAFORMA']);
            this.upsertIDPlataformaFS(client);
          }

        } else {
          //En caso de encontras solo actualiza
          // console.log('EXISTE: '+rfc);
          // client.rfc = jsonObject['R.F.C. '];
          // client.mainEmailContact = jsonObject['Mail predeterminado '];
          // client.businessName = jsonObject['Nombre ']?jsonObject['Nombre ']:'';
          // client.cveSAE = jsonObject['Clave ']?jsonObject['Clave ']:'';
          // client.ctaContable = jsonObject['Cuenta contable ']?jsonObject['Cuenta contable ']:'';
          // client.address = jsonObject['Calle ']? jsonObject['Calle ']:'';
          // client.intNumber = jsonObject['Número interior ']? jsonObject['Número interior ']:'';
          // client.extNumber = jsonObject['Número exterior ']?jsonObject['Número exterior ']:'';
          // client.square = jsonObject['Colonia ']?jsonObject['Colonia ']:'';
          // client.city = jsonObject['Municipio ']?jsonObject['Municipio ']:'';
          // client.state = jsonObject['Estado ']?jsonObject['Estado ']:'';
          // client.country = jsonObject['País ']?jsonObject['País ']:'';
          // client.cp = jsonObject['Código postal ']? jsonObject['Código postal ']:'';
          // client.taxSystem = jsonObject['Régimen fiscal ']?Utils.zfill(jsonObject['Régimen fiscal '],3):'';          
          // client.payForm = jsonObject['Forma de pago SAT ']?Utils.zfill(jsonObject['Forma de pago SAT '],3):'';
          // client.validSAT = jsonObject['ValidoSAT ']=== 1? true:false;
        }
      });

    });
  }

  validPlatformID(client: Client) {
    let valid = false;
    if (client.idPlatform == 0 || !client.idPlatform) {
      valid = true;
    }
    return valid;
  }

  existClient(clients: Array<Client>, clientRfc: string) {
    let client2search = new Client;
    for (let index = 0; index < clients.length; index++) {
      const element = clients[index];
      if (element.rfc.trim() === clientRfc) {
        client2search = element;
      }
    }
    return client2search;
  }

  // METODOS PARA LOS GRUPOS DE CLIENTES.

  getClientsByGroup(clientGroupId: string) {
    return this.afs.collection(this.clientsCollectionName, ref => ref.where('clientGroupId', '==', clientGroupId)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Client;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getGroups() {
    return this.afs.collection(this.clientGroupCollectionName, ref => ref.where('active', '==', true)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as ClientGroup;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getGroupById(clientGroupId: string) {
    return this.afs.collection(this.clientGroupCollectionName).doc(clientGroupId).snapshotChanges().pipe(
      map(action => {
        const data = action.payload.data() as ClientGroup;
        data.id = action.payload.id;
        return { ...data };
      })
    );
  }

  uspertGroup(clientGroup: ClientGroup) {
    if (!clientGroup.id) {
      clientGroup.id = this.afs.createId();
    }
    return this.afs.collection(this.clientGroupCollectionName).doc(clientGroup.id).set({
      name: clientGroup.name,
      new: clientGroup.new,
      active: clientGroup.active,
    }, { merge: true });
  }

  upsertAccountBalance(client: Client) {
    if (!client.id) {
      client.id = this.afs.createId();
    }
    return this.afs.collection(this.clientsCollectionName).doc(client.id).set({
      accountBalanceInvoice: client.accountBalanceInvoice ? client.accountBalanceInvoice : 0,
      accountBalancePayed: client.accountBalancePayed ? client.accountBalancePayed : 0,
      accountBalancePending: client.accountBalancePending ? client.accountBalancePending : 0,
      accountBalanceRefund: client.accountBalanceRefund ? client.accountBalanceRefund : 0,
    }, { merge: true });
  }

  upsertLastAppUpdate(clients:Array<Client>){
    let batch= this.afs.firestore.batch();
    clients.forEach(client=>{
      let ref = this.afs.collection(this.clientsCollectionName).doc(client.id).ref;

      ref.set({
        isRecurrent:client.isRecurrent??true,
        automaticInvoiceBlock: client.automaticInvoiceBlock ?? 1,
        company:client.company??'sitrack',
      },{merge:true});
    });
    return batch.commit();
  }
}
