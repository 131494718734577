import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NotificationLayout } from '../../../model/notification-layout';
import { NotificationsUsecase } from '../../../use-cases/shared/notifications-usecase';
import { environment } from '../../../../environments/environment';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, IMAGE_INPUT, INDENT_BUTTON, ITALIC_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_FULL_BUTTON, JUSTIFY_LEFT_BUTTON, JUSTIFY_RIGHT_BUTTON, LINK_INPUT, ORDERED_LIST_BUTTON, OUTDENT_BUTTON, REDO_BUTTON, REMOVE_FORMAT_BUTTON, SEPARATOR, STRIKE_THROUGH_BUTTON, SUBSCRIPT_BUTTON, SUPERSCRIPT_BUTTON, UNDERLINE_BUTTON, UNDO_BUTTON, UNLINK_BUTTON, UNORDERED_LIST_BUTTON } from 'ngx-simple-text-editor';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-notification-layout-builder',
  templateUrl: './notification-layout-builder.component.html',
  styleUrl: './notification-layout-builder.component.scss'
})
export class NotificationLayoutBuilderComponent implements OnInit{

  @ViewChild('closeModalNotificationLayout') closeModalNotificationLayout!:ElementRef;

  screenHeight: number;
  activeCompany:string = '';

  layouts:Array<NotificationLayout>;
  layout2Edit:NotificationLayout;

  sessionUID: string;
  sessionName: string;

  config: EditorConfig = {
    buttons: [ UNDO_BUTTON,
      REDO_BUTTON,
      REMOVE_FORMAT_BUTTON,
      SEPARATOR,
      BOLD_BUTTON,
      ITALIC_BUTTON,
      UNDERLINE_BUTTON,
      STRIKE_THROUGH_BUTTON,
      SEPARATOR,
      JUSTIFY_LEFT_BUTTON,
      JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON,
      JUSTIFY_FULL_BUTTON,
      SEPARATOR,
      ORDERED_LIST_BUTTON,
      UNORDERED_LIST_BUTTON,
      SEPARATOR,
      INDENT_BUTTON,
      OUTDENT_BUTTON,
      SEPARATOR,
      SUBSCRIPT_BUTTON,
      SUPERSCRIPT_BUTTON,
      SEPARATOR,
      FONT_SIZE_SELECT,
      LINK_INPUT,
      UNLINK_BUTTON,
      SEPARATOR,
      IMAGE_INPUT,
      FORE_COLOR,],
  };

  constructor(private _notificationsUsecase:NotificationsUsecase, private _toastrService:ToastrService,private _storage: AngularFireStorage){
    this.screenHeight = window.innerHeight;
    this.layouts = new Array<NotificationLayout>();
    this.layout2Edit = new NotificationLayout();
    this.activeCompany = localStorage.getItem(environment.sessionWorkCompany);
    this.sessionUID = localStorage.getItem(environment.sessionID)!;
    this.sessionName = localStorage.getItem(environment.sessionActualUser)!;
  }

  ngOnInit(): void {
    this.loadLayouts();
  }

    @HostListener('window:resize', ['$event'])
     onResize(event) {
       this.screenHeight = window.innerHeight;
     }

  async loadLayouts(){
    await this._notificationsUsecase.getNotificationLayoutByCompany(this.activeCompany).then(list=>{
      this.layouts = list;
    }).catch(err=>{
      console.error(err);
    })
  }

  upsertNotificationLayout(){
    console.log(this.layout2Edit);
    this.layout2Edit.idEmployeeRecord = this.sessionUID;
    this.layout2Edit.nameEmployeeRecord = this.sessionName;
    this._notificationsUsecase.upsertNotificationLayout(this.layout2Edit).then(result=>{
      this._toastrService.success('Plantilla registrada exitosamente');
      this.loadLayouts();
      this.closeModalNotificationLayout.nativeElement.click();
    });
  }

  edit(layout){
    this.layout2Edit = {...layout};
  }

  cleanLayout(){
    this.layout2Edit = new NotificationLayout();
  }
 
}
