import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ConfigCompany } from '../model/config-company';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ConfigCompanyService {

  configCompanyCollectionName: string = 'config-company';
  configCompanyLeadCollectionName: string = 'leads';

  constructor(private _afs: AngularFirestore) { }

  ///Obtenemos las configuracion de las diferentes empresas que puedan genera ordenes y 
  ///sus datos de facturacion.
  getConfigs() {
    return this._afs.collection<ConfigCompany>(this.configCompanyCollectionName, ref => ref.orderBy('priority', 'asc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as ConfigCompany;
        data.id = a.payload.doc.id;
        return { ...data };
      }))
    );
  }

  ///Obtenemos los leads de ordenes demos para la compañia seleccionada.
  getWorkordersLeads(id: string) {
    return this._afs.collection(this.configCompanyCollectionName).doc(id)
      .collection(this.configCompanyLeadCollectionName).doc('workorders').valueChanges();
  }


  ///Obtenemos los leads de ordenes demos para la compañia seleccionada.
  getDemoLeads(id: string) {
    return this._afs.collection(this.configCompanyCollectionName).doc(id)
      .collection(this.configCompanyLeadCollectionName).doc('demos').valueChanges();
  }

  getGiftsLeads(id: string) {
    return this._afs.collection(this.configCompanyCollectionName).doc(id)
      .collection(this.configCompanyLeadCollectionName).doc('gifts').valueChanges();
  }

  ///Obtenemos los leads de ordenes demos para la compañia seleccionada.
  getinvoiceFolioLeads(id: string) {
    return this._afs.collection(this.configCompanyCollectionName).doc(id).collection(this.configCompanyLeadCollectionName).doc('invoiceFolio').valueChanges();
  }

  upsertWorkorderLeads(companyId: string, last: number) {
    return this._afs.collection(this.configCompanyCollectionName).doc(companyId)
      .collection(this.configCompanyLeadCollectionName).doc('workorders').set({
        last: last
      }, { merge: true });
  }

  upsertDemoLeads(companyId: string, last: number) {
    return this._afs.collection(this.configCompanyCollectionName).doc(companyId)
      .collection(this.configCompanyLeadCollectionName).doc('demos').set({
        last: last
      }, { merge: true });
  }

  upsertGiftsLeads(companyId: string, last: number) {
    return this._afs.collection(this.configCompanyCollectionName).doc(companyId)
      .collection(this.configCompanyLeadCollectionName).doc('gifts').set({
        last: last
      }, { merge: true });
  }


  upsertInvoiceFolioLeads(companyId: string, last: number) {
    return this._afs.collection(this.configCompanyCollectionName).doc(companyId).collection(this.configCompanyLeadCollectionName).doc('invoiceFolio').set({
      last: last
    }, { merge: true });
  }
}
