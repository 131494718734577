import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { UnitRelatedInvoice } from '../../model/unit-related-invoice';
import { Utils } from '../../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class UnitInvoicesService {

  unitInvoiceCollection:string = "wo-unit-related-invoice";

  constructor(private _afs:AngularFirestore) {

   }


   /**
    * Obtener las facturas relacionadas a la unidad.
    * @param workOrderId Numero de la orden
    * @param unitId Numero de la unidad
    */
   async get(workOrderId:string, unitId:string){
    return this._afs.collection<UnitRelatedInvoice>(this.unitInvoiceCollection, ref=>ref
      .where('workOrderId','==',workOrderId)
      .where('unitId','==',unitId)
      .orderBy('recepit','asc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
       // console.log(a.payload.doc.data());
        const data = a.payload.doc.data() as UnitRelatedInvoice;
        if(data.emitDate){
          data.emitDate = Utils.timestampToDate(data.emitDate);
        }
        if(data.payitDate){
          data.payitDate = Utils.timestampToDate(data.payitDate);
        }
        data.id = a.payload.doc.id;
        return { ...data };
      }))
    );
   }

   async batchUpsertList(related:Array<UnitRelatedInvoice>){
    try {
      let batch = this._afs.firestore.batch();
      if(related){
        related.forEach(async relate=>{
          if(!relate.id){
            relate.id = this._afs.createId();
          }
          let ref = this._afs.collection(this.unitInvoiceCollection).doc(relate.id).ref;
          ref.set({
            workOrderId:relate.workOrderId,
            unitId:relate.unitId,
            unitProductId:relate.unitProductId??'',
            vin:relate.vin,
            receiptNumber:relate.receiptNumber,
            recepit:relate.recepit,
            invoiceFolio:relate.invoiceFolio,
            disabled:relate.disabled??true,
            emitDate:relate.emitDate??new Date(),
            payitDate:relate.payitDate??new Date()
          },{merge:true});
        });
      }
     batch.commit();
    } catch (error) {
      throw error;
    }
   }

   async batchDeleteList(related:Array<UnitRelatedInvoice>){
    try {
      let batch = this._afs.firestore.batch();
      if(related){
        related.forEach(async relate=>{
          let ref = this._afs.collection(this.unitInvoiceCollection).doc(relate.id).ref;
          ref.delete();
        });
      }
     batch.commit();
    } catch (error) {
      throw error;
    }
   }


   async upsertList(related:Array<UnitRelatedInvoice>){
      try {
        related.forEach(async relate=>{
          await this.upsert(relate);
        });
      } catch (error) {
        throw error;
      }
   }

   async upsert(relatedInvoice:UnitRelatedInvoice){
    try {
      if(!relatedInvoice.id){
        relatedInvoice.id = this._afs.createId();
      }
      this._afs.collection(this.unitInvoiceCollection).doc(relatedInvoice.id).set({
        workOrderId:relatedInvoice.workOrderId,
        unitId:relatedInvoice.unitId,
        unitProductId:relatedInvoice.unitProductId,
        vin:relatedInvoice.vin,
        receiptNumber:relatedInvoice.receiptNumber,
        recepit:relatedInvoice.recepit,
        invoiceFolio:relatedInvoice.invoiceFolio,
        disabled:relatedInvoice.disabled??true
      },{merge:true});
    } catch (error) {
      throw error;
    }
   }
}
