export class NotificationLayout {

    id?:string;
    titleNotification:string ='';
    subjectNotification:string= '';
    bodyNotification:string = '';
    company:string = 'sitrack';
    recordDate:Date = new Date();
    idEmployeeRecord:string = '';
    nameEmployeeRecord:string = '';

}
