
<nav
  class="navbar navbar-expand-lg navbar-dark bg-dark mb-3 top-fix"
  *ngIf="sessionToken"
>
  <div class="container-fluid">
    <a class="navbar-brand" style="position: relative">
      <img
        src="assets/images/logo-w.png"
        alt="Sitrack logo"
        style="height: 20px"
        *ngIf="company == 'sitrack'"
        
      />

      <!--img
        src="assets/images/sillion/logo_sillion_w.png"
        alt="Supernova Sillion IO logo"
        style="height: 20px"
        *ngIf="company == 'supernova'"
        
      />
      <div
        class="collapse select-company-collapse"
        [ngClass]="{ show: showCompany }"
        id="collapseSelectMainCompany"
      >
        <div class="card card-body">
          <div class="info-title mb-2">Seleccione la compañia de trabajo.</div>
          <button
            type="button"
            class="btn btn-sm btn-light mb-2"
            (click)="selectCompany2Work('sitrack')"
          >
            SITRACK
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            (click)="selectCompany2Work('supernova')"
          >
            SUPERNOVA SILLION IO
          </button>
        </div>
      </div-->
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" (click)="goto('/workorders')">
            <i class="bi bi-clipboard"></i> Ordenes
          </a>
        </li>
        <li class="nav-item dropdown" *ngIf="userPosition == 'ADMINISTRADOR' || userPosition == 'SUPERVISOR'">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-cash-coin"></i> Cobranza
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" (click)="goto('/workorders/invoices-dashboard')"
                ><i class="bi bi-file-spreadsheet"></i> Monitor</a
              >
            </li>
            <li>
              <a class="dropdown-item" (click)="goto('/workorders/invoices')" 
                ><i class="bi bi-file-spreadsheet"></i> Facturación</a
              >
            </li>

            <li>
              <a class="dropdown-item" (click)="goto('/workorders/invoice-complements')" 
                ><i class="bi bi-file-earmark-medical"></i> Complemento de
                pago</a
              >
            </li>
            <li>
              <a class="dropdown-item" (click)="goto('/workorders/invoice-builder')" 
                ><i class="bi bi-file-spreadsheet"></i> Bloques de Facturación</a>
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a class="dropdown-item" (click)="goto('/bank')" >
                <i class="bi bi-bank"></i> Pago bancarios</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a
                class="dropdown-item"
                href="https://portal.sw.com.mx/"
                target="_blank"
              >
                <i class="bi bi-link-45deg"></i> PAC-API</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="https://sistema.conectia.mx/cfdi4/users/login"
                target="_blank"
              >
                <i class="bi bi-link-45deg"></i> PAC-WEB</a
              >
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown" *ngIf="userPosition == 'ADMINISTRADOR' || userPosition == 'SUPERVISOR'">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-cash-coin"></i> CxC
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" (click)="goto('/workorders/invoice-cxc')"
                ><i class="bi bi-file-spreadsheet"></i> Segumiento</a
              >
            </li>
          </ul>
        </li>
        <li class="nav-item" *ngIf="userPosition == 'ADMINISTRADOR'">
          <a
            href="https://datastudio.google.com/reporting/122b0e94-c772-432a-9275-f0d25866bcc6"
            target="_blank"
            class="nav-link"
          >
            <i class="bi bi-bar-chart"></i> Indicadores.
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-gear"></i> Catalogos
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" (click)="goto('/catalogs/clients')" 
                ><i class="bi bi-people"></i> Clientes</a
              >
            </li>

            <li>
              <a class="dropdown-item" (click)="goto('/catalogs/products')">
                <i class="bi bi-box-seam"></i> Productos y Precios
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="goto('/catalogs/storehouse')">
                <i class="bi bi-boxes"></i> Almacén
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="goto('/plantillas')">
                <i class="bi bi-body-text"></i> Plantillas de Notificación
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            role="button"
            aria-expanded="false"
            >Exportaciones</a
          >
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" (click)="goto('/exports/orders')">
              <i class="bi bi-file-earmark-excel"></i>
              Ordenes</a></li>
              <li><a class="dropdown-item" (click)="goto('/exports/payments')">
                <i class="bi bi-file-earmark-excel"></i>
                Pagos</a></li>
            <li>
              <a class="dropdown-item" (click)="goto('/exports/validity')">
                <i class="bi bi-file-earmark-excel"></i>
                Vigencias</a>
            </li>
            <li>
              <a class="dropdown-item" (click)="goto('/exports/book-accounts')">
                <i class="bi bi-file-earmark-excel"></i>
                Cuentas Contables</a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown" *ngIf="userPosition == 'ADMINISTRADOR'">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-fingerprint"></i> Seguridad
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" (click)="goto('/security/users')">
                <i class="bi bi-person-badge"></i> Usuarios y Perfiles</a
              >
            </li>
          </ul>
        </li>
      </ul>
      <form class="d-flex">
        <h5 class="mb-0">
          <span class="badge bg-primary" *ngIf="sysEnviromentProd"
            ><i class="bi bi-shield-fill-check"></i> {{ sysVersion }}</span
          >
          <span class="badge bg-secondary" *ngIf="!sysEnviromentProd"
            ><i class="bi bi-hexagon"></i> {{ sysVersion }}</span
          >
        </h5>
        <div class="btn-group">
          <div class="btn-group dropdown" role="group">
            <button
              type="button"
              class="btn btn-dark dropdown-toggle dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="visually-hidden">Toggle Dropstart</span>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" (click)="logout()">
                  <i class="fa-solid fa-arrow-right-from-bracket"></i> Cerrar
                  Sesión</a
                >
              </li>
              <li>
                <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#notificationsSendedModal">
                  <i class="fas fa-book"></i> Notificaciones enviadas.</a
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item disabled">Versión: {{ sysVersion }}</a>
              </li>
              <li>
                <a class="dropdown-item disabled">Token: {{sessionToken.substring(0,15)}}</a>
                
              </li>
            </ul>
          </div>
          <button type="button" class="btn btn-dark">
            Hola, {{ loginService.sessionUser }}
            <i class="fa-solid fa-circle-user"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</nav>
<app-list-notifications></app-list-notifications>