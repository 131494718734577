import { UnitProduct } from "./unit-product";
import { Workorder } from "./workorder";
import { UnitRelatedInvoice } from "./unit-related-invoice";

export class Unit {

    id!:string;
    workOrderId:string = '';

    brand:string = '';
    type:string = '';
    description:string = '';
    vin:string = '';
    item:number = 0;
    plates:string = '';
    yearModel:number = 2022;
    economicNumber:string = '';
    totalEquipment:number =0;
    totalInstallation:number =0;
    totalActivation:number = 0;
    totalSubscription:number =0;

    //Informacion del holder:
    holderEquipModel:string =''
    holderEquipInstall:string ='';
    holderLastPositionDateString ='';
    holderLastPositionDate?:Date;
    holderNroPoliza:string ='';
    holderSecondaryNroPoliza:string ='';

    installInfo:boolean = false;
    emitSignal:boolean = false;
    invoiceActive:boolean = true;

    total:number = 0;
    detail:string = '';

    edit:boolean = false;
    products:Array<UnitProduct> = new Array<UnitProduct>();
    relatedInvoices?:Array<UnitRelatedInvoice> = new Array<UnitRelatedInvoice>();
    workorder?:Workorder;

    addOnRenew:boolean = true;
    constructor(){
    }

}
