import { AddedCharge } from "./added-charge";
import { Client } from "./client";
import { PaymentType } from "./payment-type";
import { PaymentWorkorder } from "./payment-workorder";
import { Unit } from "./unit";

export class Workorder {

    id?:string;
    isDemo:boolean = false;
    isGifts:boolean = false;
    company:string='sitrack';

    employeeId:string = '';
    employeeName!:string;
    otNumber:string = 'SN';
    consecutive?:number;

    clientId:string='';
    rfcClient:string = '';
    client: Client = new Client();
    validityStartDate:Date = new Date();
    validityEndDate:Date = new Date();

    installAddress:string= '';
    installProgram:string= '';
    businessType:string= '';
    business:string= '';
    payType:string= '';
    paymentType:PaymentType = {} as PaymentType;

    units:number = 0;
    amount:number = 0;
    total:number = 0;
    firstPayment:number = 0;
    nextPayment:number = 0;

    totalEquipment:number = 0;
    totalInstall:number = 0;
    totalActivation:number = 0;
    totalAnnualPayment:number = 0;
    totalAddedCharges:number = 0;
    totalReceipts:number = 0;
    detail:string ="";

    status:string = 'REGISTRADO';
    recordDate:Date = new Date();

    monthlyService:number = 1;

    payments:Array<PaymentWorkorder> = new Array<PaymentWorkorder>();
    listUnits:Array<Unit> = new Array<Unit>();

    addedCharges:Array<AddedCharge> = new Array<AddedCharge>();

    overdue?:string = 'none';

    renewBuild?:boolean = false;

    originalWorkorderId:string ='';
    originalWorkorderOtNumber:string ='';

    constructor(){
    }

}
