import { Product } from "./product";
import { ProductPortioninvoice } from "./product-portioninvoice";

export class UnitProduct {

    id?:string;
    workOrderId:string ='';
    vin:string ='';
    unitId:string ='';
    equipmentId:string ='';
    installDate:Date = new Date();
    installed:boolean = false;
    equipmentIdUninstall:string ='';
    uninstallDate:Date = new Date();
    uninstalled:boolean = false;
    
    productId:string = '';
    code:string='';
    product:Product = new Product();
    totalEquipment:number =0;
    totalInstallation:number =0;
    totalActivation:number = 0;
    totalSubscription:number =0;
    minPrice:number = 0;
    maxPrice:number = 1;
    total:number = 0;

    partials:Array<ProductPortioninvoice> = new Array<ProductPortioninvoice>();
}
