import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { UnitInvoicesService } from '../invoice/unit-invoices.service';
import { Client } from '../../model/client';
import { Product } from '../../model/product';
import { PaymentType } from '../../model/payment-type';
import { Workorder } from '../../model/workorder';
import { Unit } from '../../model/unit';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { WorkorderProductpartialService } from './workorder-productpartial.service';
import { PaymentConfig } from '../../model/payment-config';
import { Utils } from '../../shared/utils';
import { ConfigLeads } from '../../model/config-leads';
import { Version } from '../../model/version';
import { UnitProduct } from '../../model/unit-product';
import { PaymentWorkorder } from '../../model/payment-workorder';
import { BinnacleWorkorder } from '../../model/binnacle-workorder';
import { WoProductStorehouse } from '../../model/wo-product-storehouse';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})
export class WorkorderService {

  client = new Client();
  product = new Product();

  configsCollectionName = "configs";

  workOrderCollectionName = 'workorders';
  woUnitsCollectionName = 'units';
  woUnitProductsCollectionName = 'products';
  woUnitsCollectionName_ = 'wo-units';
  woUnitProductsCollectionName_ = 'wo-products';
  woUnitProductsStorehouseCollectionName = 'wo-products-storehouse';
  woPaymentsCollectionName = 'payments';
  woAddedChargesCollectionName = 'added-charges';

  woBinnacle = 'binnacle';

  orders: Array<Workorder> = [
  ];

  units: Array<Unit> = [

  ];

  paymentTypesPeriods: Array<PaymentType> = [
    new PaymentType("CONTADO", "CONTADO", 1, 1),
    new PaymentType("MENSUAL", "MENSUAL", 12, 1),
    new PaymentType("MENSUAL12", "MENSUAL 12", 12, 1),
    new PaymentType("MENSUAL24", "MENSUAL 24", 24, 1),
    new PaymentType("MENSUAL36", "MENSUAL 36", 36, 1),
    new PaymentType("SEMESTRAL", "SEMESTRAL", 2, 6),
    new PaymentType("TRIMESTRAL", "TRIMESTRAL", 4, 3),
    new PaymentType("FRACCIONADO", "FRACCIONADO", 1, 1),
  ];



  paymentTypesContado: Array<PaymentType> = [
    new PaymentType("CONTADO", "CONTADO", 1, 1),
  ];

  paymentTypesFinanciado: Array<PaymentType> = [
    new PaymentType("SEMESTRAL", "SEMESTRAL", 2, 6),
    new PaymentType("TRIMESTRAL", "TRIMESTRAL", 4, 3),
    new PaymentType("MENSUAL", "MENSUAL", 12, 1),
    new PaymentType("FRACCIONADO", "FRACCIONADO", 1, 1),
  ];

  paymentTypesFinanciadoEquipo: Array<PaymentType> = [
    new PaymentType("SEMESTRAL", "SEMESTRAL", 2, 6),
    new PaymentType("TRIMESTRAL", "TRIMESTRAL", 4, 3),
    new PaymentType("MENSUAL12", "MENSUAL 12", 12, 1),
    new PaymentType("MENSUAL24", "MENSUAL 24", 24, 1),
    new PaymentType("MENSUAL36", "MENSUAL 36", 36, 1),
    new PaymentType("FRACCIONADO", "FRACCIONADO", 1, 1),
  ];


  paymentTypesComo12: Array<PaymentType> = [
    new PaymentType("SEMESTRAL", "SEMESTRAL", 2, 6),
    new PaymentType("TRIMESTRAL", "TRIMESTRAL", 4, 3),
    new PaymentType("MENSUAL", "MENSUAL", 12, 1),
    new PaymentType("FRACCIONADO", "FRACCIONADO", 1, 1),
  ];

  paymentTypesComo24: Array<PaymentType> = [
    new PaymentType("SEMESTRAL", "SEMESTRAL", 4, 6),
    new PaymentType("TRIMESTRAL", "TRIMESTRAL", 8, 3),
    new PaymentType("MENSUAL", "MENSUAL", 24, 1),
    new PaymentType("FRACCIONADO", "FRACCIONADO", 1, 1),
  ];

  paymentTypesComo36: Array<PaymentType> = [
    new PaymentType("SEMESTRAL", "SEMESTRAL", 6, 6),
    new PaymentType("TRIMESTRAL", "TRIMESTRAL", 12, 3),
    new PaymentType("MENSUAL", "MENSUAL", 36, 1),
    new PaymentType("FRACCIONADO", "FRACCIONADO", 1, 1),
  ];

  workOrder: Workorder = {} as Workorder;



  constructor(private afs: AngularFirestore, private http: HttpClient, private _toastrService: ToastrService,
    private _workorderProductpartialService: WorkorderProductpartialService, private _unitInvoicesService: UnitInvoicesService) { }

  //Methos for general catalogs of the work order.
  getPaymentConfig() {
    const configs = new Array<PaymentConfig>();
    configs.push(new PaymentConfig('CONTADO', 'CONTADO', this.paymentTypesContado));
    configs.push(new PaymentConfig('FINANCIADO', 'FINANCIADO', this.paymentTypesFinanciado));
    configs.push(new PaymentConfig('FINANCIADO EQUIPO', 'FINANCIADO EQUIPO', this.paymentTypesFinanciadoEquipo));
    configs.push(new PaymentConfig('COMODATO12', 'COMODATO (12 MESES)', this.paymentTypesComo12));
    configs.push(new PaymentConfig('COMODATO24', 'COMODATO (24 MESES)', this.paymentTypesComo24));
    configs.push(new PaymentConfig('COMODATO36', 'COMODATO (36 MESES)', this.paymentTypesComo36));
    return configs;
  }


  //Methods related with the work order data.


  getOrderbyEmployeeid(employeeId: string) {
    //console.log(employeeId)
    return this.orders.filter(order => order.employeeId === employeeId);
  }

  getOrders() {
    return this.orders;
  }

  getWorkOrderByID(id: string) {
    return this.units;
  }

  getWorkOrderByOTNumber(otNumber: string) {
    return  this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('otNumber', '==',otNumber)).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as Workorder;
          data.id = a.payload.doc.id;
          if (data.recordDate) {
            data.recordDate = Utils.timestampToDate(data.recordDate);
          }
          if (data.validityEndDate) {
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
          }
          if (data.validityStartDate) {
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
          }
          if (!data.id) {
            data.id = a.payload.doc.id;
          }
          return { ...data };
        }))
      );
  }

  saveWorkorder(order: Workorder) {
    this.workOrder = order;
  }

  insertOrder() {
    this.workOrder.id = (this.orders.length + 1).toString();
    this.orders.push(this.workOrder);
  }

  updateWorkorder(order: Workorder) {
    //console.log(order);
  }


  //Methods related with the untis list
  getWOUnits(workorderid: string) {

  }

  getWOrdersFS() {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.orderBy('otNumber', 'desc')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as Workorder;
          data.id = a.payload.doc.id;
          if (data.recordDate) {
            data.recordDate = Utils.timestampToDate(data.recordDate);
          }
          if (data.validityEndDate) {
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
          }
          if (data.validityStartDate) {
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
          }
          if (!data.id) {
            data.id = a.payload.doc.id;
          }
          return { ...data };
        }))
      );
  }

  getWOrdersPages(lastOrder:Workorder, limit:number) {
    let orders: AngularFirestoreCollection<Workorder>;
    if(lastOrder){
      orders = this.afs.collection<Workorder>(this.workOrderCollectionName,
        ref => ref.orderBy('otNumber', 'desc').startAfter(lastOrder).limit(limit));
    } else {
      orders = this.afs.collection<Workorder>(this.workOrderCollectionName,
        ref => ref.orderBy('otNumber', 'desc').limit(limit));
    }
    return orders.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as Workorder;
          data.id = a.payload.doc.id;
          if (data.recordDate) {
            data.recordDate = Utils.timestampToDate(data.recordDate);
          }
          if (data.validityEndDate) {
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
          }
          if (data.validityStartDate) {
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
          }
          if (!data.id) {
            data.id = a.payload.doc.id;
          }
          return { ...data };
        }))
      );
  }

  getWOrdersByCompany(company: string) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('company', '==', company).orderBy('otNumber', 'desc')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as Workorder;
          data.id = a.payload.doc.id;
          if (data.recordDate) {
            data.recordDate = Utils.timestampToDate(data.recordDate);
          }
          if (data.validityEndDate) {
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
          }
          if (data.validityStartDate) {
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
          }
          return { ...data };
        }))
      );
  }

  getWOrdersByOriginalWOId(company: string, originalWorkorderId:string) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('company', '==', company).where('originalWorkorderId', '==', originalWorkorderId).orderBy('otNumber', 'desc')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as Workorder;
          data.id = a.payload.doc.id;
          if (data.recordDate) {
            data.recordDate = Utils.timestampToDate(data.recordDate);
          }
          if (data.validityEndDate) {
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
          }
          if (data.validityStartDate) {
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
          }
          return { ...data };
        }))
      );
  }

  getWOrdersNStatus(status: Array<string>) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('status', 'in', status).orderBy('otNumber', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Workorder;
        data.id = a.payload.doc.id;
        if (data.recordDate) {
          data.recordDate = Utils.timestampToDate(data.recordDate);
        }
        if (data.validityEndDate) {
          data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
        }
        if (data.validityStartDate) {
          data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
        }
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getWOrdersByStatusNPeriod(status:Array<string>, startPeriod: Date, endPeriod: Date) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('status', 'in', status).orderBy('recordDate', 'asc').startAfter(startPeriod).endBefore(endPeriod)
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Workorder;
        data.id = a.payload.doc.id;
        if (data.recordDate) {
          data.recordDate = Utils.timestampToDate(data.recordDate);
        }
        if (data.validityEndDate) {
          data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
        }
        if (data.validityStartDate) {
          data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
        }
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getWOrdersByPeriod(startPeriod: Date, endPeriod: Date) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.orderBy('recordDate', 'asc').startAfter(startPeriod).endBefore(endPeriod)
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as Workorder;
        data.id = a.payload.doc.id;
        if (data.recordDate) {
          data.recordDate = Utils.timestampToDate(data.recordDate);
        }
        if (data.validityEndDate) {
          data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
        }
        if (data.validityStartDate) {
          data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
        }
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }


  getWOrdersApprovedFS() {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('status', '==', 'APROBADO').orderBy('clientId', 'asc').orderBy('recordDate', 'asc')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as Workorder;
          data.id = a.payload.doc.id;
          data.recordDate = Utils.timestampToDate(data.recordDate);
          if (!data.id) {
            data.id = a.payload.doc.id;
          }
          return { ...data };
        }))
      );
  }

  fixCompanyForWorkorder(ids: Array<string>, company: string) {
    console.log(ids);
    for (let id of ids) {
      this.afs.collection(this.workOrderCollectionName).doc(id).set({
        'company': company
      }, { merge: true });
    }
  }

  getWOrderBYIDFS(orderId: string) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName).doc(orderId).snapshotChanges().pipe(
      map(action => {
        if (action.payload.data()) {
          const data = action.payload.data() as Workorder;

          data.id = action.payload.id;
          data.recordDate = Utils.timestampToDate(data.recordDate);
          data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
          data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
          return { ...data };
        }
        return new Workorder();
      })
    );
  }

  getWOrdersByIdEmployeeFS(employeeId: string) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('employeeId', '==', employeeId)
        .orderBy('recordDate', 'desc')).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as Workorder;
            data.id = a.payload.doc.id;
            data.recordDate = Utils.timestampToDate(data.recordDate);
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            return { ...data };
          }))
        );
  }

  getWOrdersByIdEmployeeNCompany(employeeId: string, company: string) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('employeeId', '==', employeeId).where('company', '==', company)
        .orderBy('recordDate', 'desc')).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as Workorder;
            data.id = a.payload.doc.id;
            data.recordDate = Utils.timestampToDate(data.recordDate);
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            return { ...data };
          }))
        );
  }

  getWOrdersByIdEmployeeNStatus(employeeId: string, status: Array<string>) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('employeeId', '==', employeeId).where('status', 'in', status)
        .orderBy('recordDate', 'desc')).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as Workorder;
            data.id = a.payload.doc.id;
            data.recordDate = Utils.timestampToDate(data.recordDate);
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            return { ...data };
          }))
        );
  }

  getWOrdersByClientIdFS(clientId: string) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('clientId', '==', clientId)
        .orderBy('recordDate', 'desc')).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as Workorder;
            data.id = a.payload.doc.id;
            data.recordDate = Utils.timestampToDate(data.recordDate);
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            return { ...data };
          }))
        );
  }

  getWOrdersByClientIdNCompany(clientId: string, company: string) {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('clientId', '==', clientId).where('company', '==', company)
        .orderBy('recordDate', 'desc')).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as Workorder;
            data.id = a.payload.doc.id;
            data.recordDate = Utils.timestampToDate(data.recordDate);
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            return { ...data };
          }))
        );
  }

  getWOrdersByClientIdNCompanyPaginated(clientId: string, company: string, lastOrder:Workorder, limit:number) {
    let orders:AngularFirestoreCollection<Workorder>;

    if(lastOrder){
      orders = this.afs.collection<Workorder>(this.workOrderCollectionName,
        ref => ref.where('clientId', '==', clientId).where('company', '==', company)
          .orderBy('recordDate', 'desc').startAfter(lastOrder).limit(limit));
    } else {
      orders = this.afs.collection<Workorder>(this.workOrderCollectionName,
        ref => ref.where('clientId', '==', clientId).where('company', '==', company)
          .orderBy('recordDate', 'desc').limit(limit));
    }

    return orders.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as Workorder;
            data.id = a.payload.doc.id;
            data.recordDate = Utils.timestampToDate(data.recordDate);
            data.validityEndDate = Utils.timestampToDate(data.validityEndDate);
            data.validityStartDate = Utils.timestampToDate(data.validityStartDate);
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            return { ...data };
          }))
        );
  }

  getIdFS() {
    return this.afs.createId();
  }

  upsertFS(workorder: Workorder) {
    console.log(workorder);
    return this.afs.collection(this.workOrderCollectionName).doc(workorder.id).set({
      'employeeId': workorder.employeeId,
      'isDemo': workorder.isDemo ? workorder.isDemo : false,
      'isGifts': workorder.isGifts ? workorder.isGifts : false,
      'company': workorder.company ?? 'sitrack',
      'employeeName': workorder.employeeName,
      'otNumber': workorder.otNumber,
      'rfcClient': workorder.rfcClient,
      'clientId': workorder.clientId ? workorder.clientId : workorder.client.id,
      'client': {
        'id': workorder.client.id,
        'rfc': workorder.client.rfc,
        'businessName': workorder.client.businessName,
        'address': workorder.client.address,
        'intNumber': workorder.client.intNumber ? workorder.client.intNumber : '',
        'extNumber': workorder.client.extNumber ? workorder.client.extNumber : '',
        'square': workorder.client.square ? workorder.client.square : '',
        'city': workorder.client.city ? workorder.client.city : '',
        'state': workorder.client.state ? workorder.client.state : '',
        'country': workorder.client.country ? workorder.client.country : 'MÉXICO',
        'cp': workorder.client.cp ? workorder.client.cp : null,
        'contactAdminName': workorder.client.contactAdminName ? workorder.client.contactAdminName : null,
        'contactAdminEmail': workorder.client.contactAdminEmail ? workorder.client.contactAdminEmail : null,
        'contactAdminPhone': workorder.client.contactAdminPhone ? workorder.client.contactAdminPhone : null,
        'contactOperativeName': workorder.client.contactOperativeName ? workorder.client.contactOperativeName : null,
        'contactOperativeEmail': workorder.client.contactOperativeEmail ? workorder.client.contactOperativeEmail : null,
        'contactOperativePhone': workorder.client.contactOperativePhone ? workorder.client.contactOperativePhone : null,
        'cveSAE': workorder.client.cveSAE ? workorder.client.cveSAE : null,
        'taxSystem': workorder.client.taxSystem ? workorder.client.taxSystem : null,
        'cfdiUsage': workorder.client.cfdiUsage ? workorder.client.cfdiUsage : null,
        'payForm': workorder.client.payForm ? workorder.client.payForm : null,
        'payMethod': workorder.client.payMethod ? workorder.client.payMethod : null,
        'standarInvoice': workorder.client.standarInvoice ? workorder.client.standarInvoice : false,
        'active': workorder.client.active ? workorder.client.active : true,
        'isRecurrent': workorder.client.isRecurrent ? workorder.client.isRecurrent : false,
        'mainEmailContact': workorder.client.mainEmailContact ? workorder.client.mainEmailContact : '',
        'validSAT': workorder.client.validSAT ? workorder.client.validSAT : false,
      },
      'recordDatetime': workorder.employeeId,
      'validityStartDate': workorder.validityStartDate,
      'validityEndDate': workorder.validityEndDate,
      'installAddress': workorder.installAddress,
      'installProgram': workorder.installProgram,
      'business': workorder.business,
      'businessType': workorder.businessType,
      'payType': workorder.payType,
      'units': workorder.units,
      'amount': workorder.amount,
      'totalAnnualPayment': workorder.totalAnnualPayment,
      'totalEquipment': workorder.totalEquipment,
      'totalInstall': workorder.totalInstall,
      'totalActivation': workorder.totalActivation,
      'total': workorder.total,
      'firstPayment': workorder.firstPayment,
      'nextPayment': workorder.nextPayment,
      'totalReceipts': workorder.totalReceipts,
      'detail': workorder.detail,
      'status': workorder.status,
      'recordDate': workorder.recordDate,
      'monthlyService': workorder.monthlyService,
      'originalWorkorderId': workorder.originalWorkorderId??'',
      'originalWorkorderOtNumber': workorder.originalWorkorderOtNumber??'',
    }, { merge: true });

  }

  upsertClientData(workorder: Workorder) {
    console.log(workorder);
    return this.afs.collection(this.workOrderCollectionName).doc(workorder.id).set({
      'client': {
        'id': workorder.client.id,
        'idPlatform': workorder.client.idPlatform ? workorder.client.idPlatform : null,
        'rfc': workorder.client.rfc,
        'businessName': workorder.client.businessName,
        'address': workorder.client.address,
        'intNumber': workorder.client.intNumber ? workorder.client.intNumber : '',
        'extNumber': workorder.client.extNumber ? workorder.client.extNumber : '',
        'square': workorder.client.square ? workorder.client.square : '',
        'city': workorder.client.city ? workorder.client.city : '',
        'state': workorder.client.state ? workorder.client.state : '',
        'country': workorder.client.country ? workorder.client.country : 'MÉXICO',
        'cp': workorder.client.cp ? workorder.client.cp : null,
        'cveSAE': workorder.client.cveSAE ? workorder.client.cveSAE : null,
        'ctaContable': workorder.client.ctaContable ? workorder.client.ctaContable : null,
        'taxSystem': workorder.client.taxSystem ? workorder.client.taxSystem : null,
        'cfdiUsage': workorder.client.cfdiUsage ? workorder.client.cfdiUsage : null,
        'payForm': workorder.client.payForm ? workorder.client.payForm : null,
        'payMethod': workorder.client.payMethod ? workorder.client.payMethod : null,
        'standarInvoice': workorder.client.standarInvoice ? workorder.client.standarInvoice : false,
        'active': workorder.client.active ? workorder.client.active : true,
        'isInsuranceCarrier': workorder.client.isInsuranceCarrier ? workorder.client.isInsuranceCarrier : false,
        'isRecurrent': workorder.client.isRecurrent ? workorder.client.isRecurrent : false,
        'isNewRecord': workorder.client.isNewRecord ? workorder.client.isNewRecord : false,
        'mainEmailContact': workorder.client.mainEmailContact ? workorder.client.mainEmailContact : '',
        'validSAT': workorder.client.validSAT ? workorder.client.validSAT : false,
      }
    }, { merge: true });
  }

  upsertStatusFS(workorder: Workorder) {
    console.log(workorder);
    return this.afs.collection(this.workOrderCollectionName).doc(workorder.id).set({
      'status': workorder.status,
      'totalReceipts': workorder.totalReceipts,
      'units': workorder.units
    }, { merge: true });
  }

  updateStatusWOFS(workOrder: Workorder) {
    return this.afs.collection(this.workOrderCollectionName).doc(workOrder.id).set({
      'status': workOrder.status
    }, { merge: true });
  }

  upsertOrderNumberFS(workorder: Workorder) {
    return this.afs.collection(this.workOrderCollectionName).doc(workorder.id).set({
      'otNumber': workorder.otNumber,
      'consecutive': workorder.consecutive
    }, { merge: true });

  }

  getConfigLeads() {
    return this.afs.collection<ConfigLeads>(this.configsCollectionName).doc('leads').valueChanges();
  }

  getConfigLogo(id: string) {
    return this.afs.collection<ConfigLeads>(this.configsCollectionName).doc(id).valueChanges();
  }

  getConfigEmails() {
    return this.afs.collection(this.configsCollectionName).doc('email-cc-notification').valueChanges();
  }

  getConfigInvoiceFolio() {
    return this.afs.collection(this.configsCollectionName).doc('invoice-folio').valueChanges();
  }

  checkActualVersion() {
    return this.afs.collection<Version>(this.configsCollectionName).doc('version').snapshotChanges().pipe(
      map(action => {
        const data = action.payload.data() as Version;
        //console.log(data);
        if (data.releaseDate) {
          data.releaseDate = Utils.timestampToDate(data.releaseDate);
        }
        return { ...data };
      })
    );
  }

  upsertConfigLeadsWorkorders(configLeads: ConfigLeads) {
    return this.afs.collection(this.configsCollectionName).doc('leads').set({
      workorders: configLeads.workorders
    }, { merge: true });
  }

  upsertConfigLeadsDemo(configLeads: ConfigLeads) {
    return this.afs.collection(this.configsCollectionName).doc('leads').set({
      demos: configLeads.demos
    }, { merge: true });
  }

  upsertConfigLeadsInvoiceFolio(configLeads: ConfigLeads) {
    return this.afs.collection(this.configsCollectionName).doc('leads').set({
      invoiceFolio: configLeads.invoiceFolio
    }, { merge: true });
  }

  upsertConfigLeadsInvoicePayFolio(configLeads: ConfigLeads) {
    return this.afs.collection(this.configsCollectionName).doc('leads').set({
      invoicePayFolio: configLeads.invoicePayFolio
    }, { merge: true });
  }

  getLastConsecutive() {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('otNumber', '!=', 'SN').orderBy('otNumber', 'desc').orderBy('recordDate', 'desc')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as Workorder;
          data.id = a.payload.doc.id;
          data.recordDate = Utils.timestampToDate(data.recordDate);
          if (!data.id) {
            data.id = a.payload.doc.id;
          }
          return { ...data };
        }))
      );
  }

  getLastDemoConsecutive() {
    return this.afs.collection<Workorder>(this.workOrderCollectionName,
      ref => ref.where('otNumber', '!=', 'DEMO').orderBy('otNumber', 'desc').orderBy('recordDate', 'desc')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as Workorder;
          data.id = a.payload.doc.id;
          data.recordDate = Utils.timestampToDate(data.recordDate);
          if (!data.id) {
            data.id = a.payload.doc.id;
          }
          return { ...data };
        }))
      );
  }

  upsertWOCalculate(workorder: Workorder) {
    return this.afs.collection(this.workOrderCollectionName).doc(workorder.id).set({
      'totalEquipment': workorder.totalEquipment,
      'totalInstall': workorder.totalInstall,
      'totalAnnualPayment': workorder.totalAnnualPayment,
      'totalActivation':workorder.totalActivation,
      'units': workorder.units,
      'amount': workorder.amount,
      'total': workorder.total,
      'firstPayment': workorder.firstPayment,
      'status': workorder.status,
    }, { merge: true });
  }

  /**
   * METODOS DE UNIDADES DENTRO DE LA ORDEN DE TRABAJO
   */

  getUnitsWOFS(workorderId: string) {
    //console.log(workorderId);
    return this.afs.collection<Unit>(this.workOrderCollectionName).doc(workorderId).collection(this.woUnitsCollectionName).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Unit;
        data.id = a.payload.doc.id;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  async batchGetUnitsWOFS(workorderId: string, units: Array<Unit>) {
    try {
      let batch = this.afs.firestore.batch();


      let ref = this.afs.collection<Unit>(this.woUnitsCollectionName_).ref;

      ref.where('workOrderId', '==', workorderId).orderBy('vin', 'desc').get().then(result => {
        result.docs.forEach(element => {
          console.log(element.data() as Unit);
          let unit = element.data() as Unit;
          unit.products = new Array<UnitProduct>();

          /*let prodRef =this.afs.collection<UnitProduct>(this.woUnitProductsCollectionName_).ref;
          prodRef.where('workOrderId','==',workorderId).where('unitId','==',unit.id).get().then(resultProd=>{
            resultProd.docs.forEach(prodRes=>{
              let unitProduct = prodRes.data() as UnitProduct;
              unit.products.push(unitProduct);
            })
          })*/
        });
      })

      return batch.commit();
    } catch (error) {
      throw error;
    }
  }

  getUnitsWOFS_(workorderId: string) {
    //console.log(workorderId);
    return this.afs.collection<Unit>(this.woUnitsCollectionName_, ref => ref.where('workOrderId', '==', workorderId).orderBy('vin', 'desc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Unit;
        data.id = a.payload.doc.id;
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getUnitsWOByID(unitId: string) {
    return this.afs.collection<Unit>(this.woUnitsCollectionName_).doc(unitId).snapshotChanges().pipe(
        map(a => {
          const data = a.payload.data() as Unit;
          if(data){
            data.id = a.payload.id;
          }
          return { ...data };
        })
      );
  }

  getUnitsWOByVIN(vin: string) {
    return this.afs.collection<Unit>(this.woUnitsCollectionName_,
      ref => ref.where('vin', '>=', vin).where('vin', '<', vin + '')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Unit;
          data.id = a.payload.doc.id;
          return { ...data };
        }))
      );
  }

  getUnitsByVIN(vin: string) {
    return this.afs.collection<Unit>(this.woUnitsCollectionName_,
      ref => ref.where('vin', '==', vin)).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Unit;
          data.id = a.payload.doc.id;
          return { ...data };
        }))
      );
  }

  getUnitProductsWOFS(workorderId: string, unitId: string) {
    return this.afs.collection<UnitProduct>(this.workOrderCollectionName).doc(workorderId)
      .collection(this.woUnitsCollectionName).doc(unitId)
      .collection(this.woUnitProductsCollectionName)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as UnitProduct;
          data.id = a.payload.doc.id;
          if (!data.id) {
            data.id = a.payload.doc.id;
          }
          return { ...data };
        }))
      );
  }

  getUnitProductsWorOrderId(workorderId: string) {
    return this.afs.collection<UnitProduct>(this.woUnitProductsCollectionName_,
      ref => ref.where('workOrderId', '==', workorderId).orderBy('unitId', 'desc'))
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as UnitProduct;
          data.id = a.payload.doc.id;
          if (data.installDate) {
            data.installDate = Utils.timestampToDate(data.installDate);
          }
          if (data.uninstallDate) {
            data.uninstallDate = Utils.timestampToDate(data.uninstallDate);
          }
          return { ...data };
        }))
      );
  }

  getUnitProductsWOFS_(workorderId: string, unitId: string) {
    return this.afs.collection<UnitProduct>(this.woUnitProductsCollectionName_,
      ref => ref.where('workOrderId', '==', workorderId).where('unitId', '==', unitId))
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as UnitProduct;
          data.id = a.payload.doc.id;
          if (!data.id) {
            data.id = a.payload.doc.id;
          }
          if (data.installDate) {
            data.installDate = Utils.timestampToDate(data.installDate);
          }
          if (data.uninstallDate) {
            data.uninstallDate = Utils.timestampToDate(data.uninstallDate);
          }
          return { ...data };
        }))
      );
  }

  upsertUnitWOFS(workorderId: string, units: Array<Unit>) {
    //console.log(workorderId);
    //console.log(units);
    /**Iterar todas la lista de unidades para registrar dentro de la orden */
    units.forEach(unit => {
      if (!unit.id) {
        unit.id = this.afs.createId();
      }
      this.afs.collection<Unit>(this.workOrderCollectionName).doc(workorderId)
        .collection(this.woUnitsCollectionName).doc(unit.id).set({
          'brand': unit.brand ? unit.brand.toUpperCase() : '',
          'type': unit.type.toUpperCase(),
          'description': unit.description ? unit.description.toUpperCase() : '',
          'vin': unit.vin ? unit.vin : '',
          'plates': unit.plates ? unit.plates : '',
          'yearModel': unit.yearModel ? unit.yearModel : 2022,
          'economicNumber': unit.economicNumber ? unit.economicNumber : '',
          'totalEquipment': unit.totalEquipment,
          'totalInstallation': unit.totalInstallation,
          'totalSubscription': unit.totalSubscription,
          'totalActivation': unit.totalActivation,
          'total': unit.total,
          'detail': unit.detail ? unit.detail : '',
          'item':unit.item??0
        }, { merge: true });
      this.upsertUnitProducts(workorderId, unit.id, unit.products);
      //TODO: Gardar las facturas relacionadas.
      this._unitInvoicesService.upsertList(unit.relatedInvoices!);
    });
  }

  async batchUpsertUnitsWO(workorderId: string, units: Array<Unit>) {
    let batch = this.afs.firestore.batch();
    units.forEach(async unit => {
      //console.log(unit);
      if (!unit.id) {
        unit.id = this.afs.createId();
      }
      let ref = this.afs.collection(this.woUnitsCollectionName_).doc(unit.id).ref;
      ref.set({
        'workOrderId': workorderId,
        'brand': unit.brand ??'',
        'type': unit.type??'',
        'description': unit.description ??'',
        'vin': unit.vin ?? '',
        'item': unit.item??0,
        'plates': unit.plates ?? '',
        'yearModel': unit.yearModel ?? 2022,
        'economicNumber': unit.economicNumber ?? '',
        'totalEquipment': unit.totalEquipment??0,
        'totalInstallation': unit.totalInstallation??0,
        'totalSubscription': unit.totalSubscription??0,
        'totalActivation':unit.totalActivation??0,
        'total': unit.total??0,
        'detail': unit.detail ?? '',
        'holderEquipModel': unit.holderEquipModel ?? '',
        'holderEquipInstall': unit.holderEquipInstall ?? '',
        'holderLastPositionDateString': unit.holderLastPositionDateString ?? '',
        'holderLastPositionDate': unit.holderLastPositionDate ?? null,
        'holderNroPoliza': unit.holderNroPoliza ?? '',
        'holderSecondaryNroPoliza': unit.holderSecondaryNroPoliza ?? '',
        'installInfo': unit.installInfo ?? false,
        'emitSignal': unit.emitSignal ?? false,
        'invoiceActive': unit.invoiceActive ?? true,
      }, { merge: true });
      
      //Agregamos el batch del producto que queremos guardar.
      if(unit.products){
        await this.batchUpsertUnitProducts(batch, workorderId, unit.id, unit.products);
      }
      
    });
    return batch.commit();
  }

  upsertUnitWOFS_(workorderId: string, units: Array<Unit>) {
    //console.log(workorderId);
    //console.log(units);
    /**Iterar todas la lista de unidades para registrar dentro de la orden */
    units.forEach(unit => {
      if (!unit.id) {
        unit.id = this.afs.createId();
      }
      //console.log(unit.id);
      this.afs.collection(this.woUnitsCollectionName_).doc(unit.id).set({
        'workOrderId': workorderId,
        'brand': unit.brand ? unit.brand : '',
        'type': unit.type,
        'description': unit.description ? unit.description : '',
        'vin': unit.vin ? unit.vin : '',
        'plates': unit.plates ? unit.plates : '',
        'yearModel': unit.yearModel ? unit.yearModel : 2022,
        'economicNumber': unit.economicNumber ? unit.economicNumber : '',
        'totalEquipment': unit.totalEquipment,
        'totalInstallation': unit.totalInstallation,
        'totalSubscription': unit.totalSubscription,
        'totalActivation':unit.totalActivation,
        'total': unit.total,
        'detail': unit.detail ? unit.detail : '',
        'holderEquipModel': unit.holderEquipModel ? unit.holderEquipModel : '',
        'holderEquipInstall': unit.holderEquipInstall ? unit.holderEquipInstall : '',
        'holderLastPositionDateString': unit.holderLastPositionDateString ? unit.holderLastPositionDateString : '',
        'holderLastPositionDate': unit.holderLastPositionDate ? unit.holderLastPositionDate : null,
        'holderNroPoliza': unit.holderNroPoliza ? unit.holderNroPoliza : '',
        'holderSecondaryNroPoliza': unit.holderSecondaryNroPoliza ? unit.holderSecondaryNroPoliza : '',
        'installInfo': unit.installInfo ? unit.installInfo : false,
        'emitSignal': unit.emitSignal ? unit.emitSignal : false,
        'invoiceActive': unit.invoiceActive ?? true,
        'item':unit.item??0
      }, { merge: true });
      this.upsertUnitProducts_(workorderId, unit.id, unit.products);
      this._unitInvoicesService.upsertList(unit.relatedInvoices!);
    });
  }

  upsertUnitHolderFS_(unit: Unit) {
    this.afs.collection(this.woUnitsCollectionName_).doc(unit.id).set({
      'holderEquipModel': unit.holderEquipModel ? unit.holderEquipModel : '',
      'holderEquipInstall': unit.holderEquipInstall ? unit.holderEquipInstall : '',
      'holderLastPositionDateString': unit.holderLastPositionDateString ? unit.holderLastPositionDateString : '',
      'holderLastPositionDate': unit.holderLastPositionDate ? unit.holderLastPositionDate : null,
      'holderNroPoliza': unit.holderNroPoliza ? unit.holderNroPoliza : '',
      'holderSecondaryNroPoliza': unit.holderSecondaryNroPoliza ? unit.holderSecondaryNroPoliza : '',
      'installInfo': unit.installInfo ? unit.installInfo : false,
      'emitSignal': unit.emitSignal ? unit.emitSignal : false,
    }, { merge: true });
  }

  upsertUnitActiveInvoice_(unit: Unit) {
    this.afs.collection(this.woUnitsCollectionName_).doc(unit.id).set({
      'invoiceActive': unit.invoiceActive
    }, { merge: true });
  }

  batchUpsertUnitHolderFS_(units:Array<Unit>) {
    let batch = this.afs.firestore.batch();

    units.forEach(unit=>{
      console.log(unit);
      let ref = this.afs.collection(this.woUnitsCollectionName_).doc(unit.id).ref;
      
      ref.set({
        'holderEquipModel': unit.holderEquipModel ? unit.holderEquipModel : '',
        'holderEquipInstall': unit.holderEquipInstall ? unit.holderEquipInstall : '',
        'holderLastPositionDateString': unit.holderLastPositionDateString ? unit.holderLastPositionDateString : '',
        'holderLastPositionDate': unit.holderLastPositionDate ? unit.holderLastPositionDate : null,
        'holderNroPoliza': unit.holderNroPoliza ? unit.holderNroPoliza : '',
        'holderSecondaryNroPoliza': unit.holderSecondaryNroPoliza ? unit.holderSecondaryNroPoliza : '',
        'installInfo': unit.installInfo ? unit.installInfo : false,
        'emitSignal': unit.emitSignal ? unit.emitSignal : false,
      }, { merge: true });
    });
    return batch.commit();
  }

  upsertUnitsPriceWOFS_(workorderId: string, unit: Unit) {
    //console.log(workorderId);
    //console.log(unit);
    if (!unit.id) {
      unit.id = this.afs.createId();
      // console.log(unit.id);
    }
    return this.afs.collection(this.woUnitsCollectionName_).doc(unit.id).set({
      'workOrderId': workorderId,
      'brand': unit.brand ? unit.brand : '',
      'type': unit.type,
      'description': unit.description ? unit.description : '',
      'vin': unit.vin ? unit.vin : '',
      'plates': unit.plates ? unit.plates : '',
      'yearModel': unit.yearModel ? unit.yearModel : 2022,
      'economicNumber': unit.economicNumber ? unit.economicNumber : '',
      'totalEquipment': unit.totalEquipment,
      'totalInstallation': unit.totalInstallation,
      'totalSubscription': unit.totalSubscription,
      'totalActivation':unit.totalActivation,
      'total': unit.total,
      'detail': unit.detail ? unit.detail : '',
      'item':unit.item??0
    }, { merge: true }).then(() => {
      this.upsertUnitProducts_(workorderId, unit.id, unit.products);
    });

  }

  upsertUnitProducts(workorderId: string, unitId: string, products: Array<UnitProduct>) {
    products.forEach(product => {
      if (!product.id) {
        product.id = this.afs.createId();
      }
      this.afs.collection<Unit>(this.workOrderCollectionName).doc(workorderId)
        .collection(this.woUnitsCollectionName).doc(unitId)
        .collection(this.woUnitProductsCollectionName).doc(product.id).set({
          'productId': product.productId,
          'code': product.code,
          'product': product.product,
          'totalEquipment': product.totalEquipment,
          'totalInstallation': product.totalInstallation,
          'totalSubscription': product.totalSubscription,
          'totalActivation':product.totalActivation,
          'minPrice': product.minPrice,
          'maxPrice': product.maxPrice,
          'total': product.total
        }, { merge: true });
    });
  }

  getBatch() {
    return this.afs.firestore.batch();
  }

  batchUpsertUnitProducts(batch: any, workOrderId: string, unitId: string, products: Array<UnitProduct>) {
    products.forEach(unitProduct => {
      let ref = this.afs.collection(this.woUnitProductsCollectionName_).doc(unitProduct.id).ref;
      ref.set({
        workOrderId: workOrderId,
        unitId: unitId,
        equipmentId: unitProduct.equipmentId ? unitProduct.equipmentId : '',
        installDate: unitProduct.installDate ? unitProduct.installDate : new Date(),
        installed: unitProduct.installed ? unitProduct.installed : false,
        equipmentIdUninstall: unitProduct.equipmentIdUninstall ? unitProduct.equipmentIdUninstall : '',
        uninstallDate: unitProduct.uninstallDate ? unitProduct.uninstallDate : new Date(),
        uninstalled: unitProduct.uninstalled ? unitProduct.uninstalled : false,
        productId: unitProduct.product.id,
        code: unitProduct.code,
        product: unitProduct.product,
        totalEquipment: unitProduct.totalEquipment,
        totalInstallation: unitProduct.totalInstallation,
        totalSubscription: unitProduct.totalSubscription,
        totalActivation:unitProduct.totalActivation,
        minPrice: unitProduct.minPrice,
        maxPrice: unitProduct.maxPrice,
        total: unitProduct.total
      }, { merge: true });
    });
  }

  batchUpsertUnitProductsInstallInfo(batch: any, products: Array<UnitProduct>) {
    let productBatch = this.afs.firestore.batch();
    products.forEach(unitProduct => {
      let ref = this.afs.collection(this.woUnitProductsCollectionName_).doc(unitProduct.id).ref;
      ref.set({
        equipmentId: unitProduct.equipmentId ? unitProduct.equipmentId : '',
        installDate: unitProduct.installDate ? unitProduct.installDate : new Date(),
        installed: unitProduct.installed ? unitProduct.installed : false,
        equipmentIdUninstall: unitProduct.equipmentIdUninstall ? unitProduct.equipmentIdUninstall : '',
        uninstallDate: unitProduct.uninstallDate ? unitProduct.uninstallDate : new Date(),
        uninstalled: unitProduct.uninstalled ? unitProduct.uninstalled : false,
      }, { merge: true });
    });
    productBatch.commit();
  }

  upsertUnitProducts_(workOrderId: string, unitId: string, products: Array<UnitProduct>) {
    products.forEach(product => {
      if (!product.id) {
        product.id = this.afs.createId();
      }
      this.afs.collection(this.woUnitProductsCollectionName_).doc(product.id).set({
        workOrderId: workOrderId,
        unitId: unitId,
        equipmentId: product.equipmentId ? product.equipmentId : '',
        installDate: product.installDate ? product.installDate : new Date(),
        installed: product.installed ? product.installed : false,
        equipmentIdUninstall: product.equipmentIdUninstall ? product.equipmentIdUninstall : '',
        uninstallDate: product.uninstallDate ? product.uninstallDate : new Date(),
        uninstalled: product.uninstalled ? product.uninstalled : false,
        productId: product.product.id,
        code: product.code,
        product: product.product,
        totalEquipment: product.totalEquipment,
        totalInstallation: product.totalInstallation,
        totalSubscription: product.totalSubscription,
        totalActivation:product.totalActivation,
        minPrice: product.minPrice,
        maxPrice: product.maxPrice,
        total: product.total
      }, { merge: true });
    });
  }


  deleteUnitProduct(workorderId: string, unitId: string, productId: string) {
    this.afs.collection<Unit>(this.workOrderCollectionName).doc(workorderId)
      .collection(this.woUnitsCollectionName).doc(unitId)
      .collection(this.woUnitProductsCollectionName).doc(productId).delete();
  }

  deleteUnitProduct_(workorderId: string, unitId: string, productId: string) {
    console.log("Producto Borrado: " + productId);
    return this.afs.collection<Unit>(this.woUnitProductsCollectionName_).doc(productId).delete();
  }

  getPaymentOFS(workorderId: string) {
    return this.afs.collection<PaymentWorkorder>(this.workOrderCollectionName).doc(workorderId)
      .collection(this.woPaymentsCollectionName, ref => ref.orderBy('payDate', 'asc')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as PaymentWorkorder;
          data.id = a.payload.doc.id;
          data.payDate = Utils.timestampToDate(data.payDate);
          if (!data.id) {
            data.id = a.payload.doc.id;
          }
          return { ...data };
        }))
      );
  }

  /**
   * 
   * @param workorderId Identificador de la orden
   * @param payments
   */
  upsertPaymentOFS(workorderId: string, payments: Array<PaymentWorkorder>) {
    //console.log(workorderId);
    /**Iterar todas la lista de unidades para registrar dentro de la orden */
    console.log('PAGOS>>>>>' + payments.length);
    payments.forEach(payment => {
      payment.payDate = new Date(payment.payDate);
      if (!payment.id) {
        payment.id = this.afs.createId();
      }
      this.afs.collection(this.workOrderCollectionName).doc(workorderId).collection(this.woPaymentsCollectionName).doc(payment.id).set(
        {
          'percentageEquipment': payment.percentageEquipment,
          'paymentEquipment': payment.paymentEquipment,
          'percentageInstall': payment.percentageInstall,
          'paymentInstall': payment.paymentInstall,
          'percentageActivation': payment.percentageActivation,
          'paymentActivation': payment.paymentActivation,
          'percentageAnnualPay': payment.percentageAnnualPay,
          'paymentAnnualPay': payment.paymentAnnualPay,
          'additionals': payment.additionals,
          'total': payment.total,
          'totalTaxes': payment.totalTaxes,
          'payDate': payment.payDate,
          'first': payment.first ? payment.first : false,
          'status': payment.invoice ? payment.status : 'PENDIENTE',
          'invoice': payment.invoice ? payment.invoice : '',
        }, { merge: true }).catch(console.log);
    });
  }


  deletUnit(workorderId: string, unitId: string) {
    //console.log(workorderId+', '+unitId);
    return this.afs.collection(this.workOrderCollectionName).doc(workorderId).collection(this.woUnitsCollectionName).doc(unitId).delete();
  }

  deletUnit_(workorderId: string, unitId: string) {
    //console.log(workorderId+', '+unitId);
    return this.afs.collection(this.woUnitsCollectionName_).doc(unitId).delete();
  }

  deletePayment(workOrderId: string, paymentId: string) {
    //console.log(workOrderId+', '+paymentId);
    return this.afs.collection(this.workOrderCollectionName).doc(workOrderId).collection(this.woPaymentsCollectionName).doc(paymentId).delete();
  }

  /**
   * SECCION PARA LAS OPERACIONES DE REGISTRO DE BITACORA DE LA ORDEN.
   */

  upsertBinnacle(workOrderId: string, binnacle: BinnacleWorkorder) {
    if (binnacle.id) {
      binnacle.id = this.afs.createId();
    }
    return this.afs.collection(this.workOrderCollectionName).doc(workOrderId).collection(this.woBinnacle).doc(binnacle.id).set({
      userId: binnacle.userId,
      userName: binnacle.userName,
      recordDate: binnacle.recordDate,
      activity: binnacle.activity,
    }, { merge: true })
  }

  getWOBinnacleFS(workOrderId: string) {
    return this.afs.collection<BinnacleWorkorder>(this.workOrderCollectionName).doc(workOrderId).collection(this.woBinnacle, ref => ref.orderBy('recordDate', 'asc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as BinnacleWorkorder;
        data.id = a.payload.doc.id;
        data.recordDate = Utils.timestampToDate(data.recordDate);
        if (!data.id) {
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  loadExcelMasiveUnits(url: string, units: Array<Unit>, libraryProducts: Array<Product>) {
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe(result => {
      const data = new Uint8Array(result);
      const workbook = XLSX.read(data, { type: 'array' });
      // console.log(workbook);

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonParseWorkbookSheet: object[] = XLSX.utils.sheet_to_json(sheet);

      const temporalUnits = new Array<Unit>();
      this._toastrService.info('Total: ' + jsonParseWorkbookSheet.length + ' registros;', 'Carga Masiva');
      //console.log(JSON.stringify(jsonParseWorkbookSheet));
      this.http.post('https://us-central1-web-sitrack-ot.cloudfunctions.net/orderUnitsLoad', JSON.stringify(jsonParseWorkbookSheet),
        {
          headers: {
            'content-type': 'application/json; charset=utf-8'
          }
        });

      jsonParseWorkbookSheet.forEach(jsonObject => {

        const unitLoaded = new Unit();
        unitLoaded.products = new Array<UnitProduct>();

        unitLoaded.type = jsonObject['TIPO'];
        unitLoaded.vin = jsonObject['VIN'];
        unitLoaded.plates = jsonObject['PLACAS'];
        unitLoaded.economicNumber = jsonObject['ECONOMICO'];
        unitLoaded.brand = jsonObject['MARCA'];
        unitLoaded.description = jsonObject['MODELO'];
        unitLoaded.detail = jsonObject['DETALLES'];
        unitLoaded.yearModel = jsonObject['AÑO'];

        /*if(jsonObject['PRODUCTO1']){
          this.loadProductToUnit(jsonObject['PRODUCTO1'].trim(),unitLoaded.products, libraryProducts);
        }

        if(jsonObject['PRODUCTO2']){
          this.loadProductToUnit(jsonObject['PRODUCTO2'].trim(),unitLoaded.products, libraryProducts);
        }

        if(jsonObject['PRODUCTO3']){
          this.loadProductToUnit(jsonObject['PRODUCTO3'].trim(),unitLoaded.products, libraryProducts);
        }

        if(jsonObject['PRODUCTO4']){
          this.loadProductToUnit(jsonObject['PRODUCTO4'].trim(),unitLoaded.products, libraryProducts);
        }

        if(jsonObject['PRODUCTO5']){
          this.loadProductToUnit(jsonObject['PRODUCTO5'].trim(),unitLoaded.products, libraryProducts);
        }*/

        //Cargamos los productos

        units.push(unitLoaded);

      });

    });
  }

  async loadExcelUnits(url: string, units: Array<Unit>, libraryProducts: Array<Product>) {
    console.log(libraryProducts);
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe(result => {
      const data = new Uint8Array(result);
      const workbook = XLSX.read(data, { type: 'array' });
      // console.log(workbook);

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonParseWorkbookSheet: object[] = XLSX.utils.sheet_to_json(sheet);

      const temporalUnits = new Array<Unit>();
      //this.snotifyService.info('Total: '+jsonParseWorkbookSheet.length+ ' registros;','Carga Masiva');

      // console.log(libraryProducts);

      jsonParseWorkbookSheet.forEach(jsonObject => {

        const unitLoaded = new Unit();
        unitLoaded.products = new Array<UnitProduct>();

        unitLoaded.type = jsonObject['TIPO'];
        unitLoaded.vin = jsonObject['VIN'];
        unitLoaded.plates = jsonObject['PLACAS'];
        unitLoaded.economicNumber = jsonObject['ECONOMICO'];
        unitLoaded.brand = jsonObject['MARCA'];
        unitLoaded.description = jsonObject['MODELO'];
        unitLoaded.detail = jsonObject['DETALLES'];
        unitLoaded.yearModel = jsonObject['AÑO'];
        unitLoaded.invoiceActive = true;



        if (jsonObject['PRODUCTO1']) {
          //this.loadProductToUnit(jsonObject['PRODUCTO1'].trim(),unitLoaded.products, libraryProducts, jsonObject);
          this.loadProductToUnitNPrice('PRODUCTO1', unitLoaded.products, libraryProducts, jsonObject);
        }

        if (jsonObject['PRODUCTO2']) {
          //this.loadProductToUnit(jsonObject['PRODUCTO2'].trim(),unitLoaded.products, libraryProducts, jsonObject);
          this.loadProductToUnitNPrice('PRODUCTO2', unitLoaded.products, libraryProducts, jsonObject);
        }

        if (jsonObject['PRODUCTO3']) {
          // this.loadProductToUnit(jsonObject['PRODUCTO3'].trim(),unitLoaded.products, libraryProducts, jsonObject);
          this.loadProductToUnitNPrice('PRODUCTO3', unitLoaded.products, libraryProducts, jsonObject);
        }

        if (jsonObject['PRODUCTO4']) {
          //this.loadProductToUnit(jsonObject['PRODUCTO4'].trim(),unitLoaded.products, libraryProducts, jsonObject);
          this.loadProductToUnitNPrice('PRODUCTO4', unitLoaded.products, libraryProducts, jsonObject);
        }

        if (jsonObject['PRODUCTO5']) {
          //this.loadProductToUnit(jsonObject['PRODUCTO5'].trim(),unitLoaded.products, libraryProducts, jsonObject);
          this.loadProductToUnitNPrice('PRODUCTO5', unitLoaded.products, libraryProducts, jsonObject);
        }

        //Cargamos los productos

        units.push(unitLoaded);

      });

    });
  }

  loadProductToUnit(code: string, unitProdcts: Array<UnitProduct>, libraryProducts: Array<Product>, jsonObject: object) {
    libraryProducts.forEach(product => {
      if (product.code === code) {
        const loadUnitProduct = new UnitProduct();

        loadUnitProduct.code = code;
        loadUnitProduct.product = product;
        loadUnitProduct.maxPrice = product.priceList[0].totalMax;
        loadUnitProduct.minPrice = product.priceList[0].totalMin;
        loadUnitProduct.totalEquipment = product.priceList[0].equipmentMax;
        loadUnitProduct.totalInstallation = product.priceList[0].installMax;
        loadUnitProduct.totalSubscription = product.priceList[0].annualSubscriptionMax;
        loadUnitProduct.totalActivation = product.priceList[0].activationMax;
        loadUnitProduct.total = product.priceList[0].activationMax + product.priceList[0].annualSubscriptionMax;
        loadUnitProduct.productId = product.id;

        unitProdcts.push(loadUnitProduct);
      }
    });
  }

  loadProductToUnitNPrice(code: string, unitProdcts: Array<UnitProduct>, libraryProducts: Array<Product>, jsonObject: object) {
    //Obtenemos el codigo del objeto de JSON.
    const productCode = jsonObject[code].trim();
    //Buscamos todos los objetos dentro de la libreria
    libraryProducts.forEach(product => {
      const loadUnitProduct = new UnitProduct();
      if (product.code === productCode.trim()) {
        //onsole.log(product);
        loadUnitProduct.code = productCode;
        loadUnitProduct.product = product;
        try {
          loadUnitProduct.maxPrice = product.priceList[0].totalMax;
          loadUnitProduct.minPrice = product.priceList[0].totalMin;
          if (!jsonObject[code + 'EQUIPO'] && !jsonObject[code + 'INSTALACION'] && !jsonObject[code + 'ABONO']) {
            loadUnitProduct.totalEquipment = product.priceList[0].equipmentMax;
            loadUnitProduct.totalInstallation = product.priceList[0].installMax;
            loadUnitProduct.totalSubscription = product.priceList[0].annualSubscriptionMax;
            loadUnitProduct.totalActivation = product.priceList[0].activationMax;
          } else {
            loadUnitProduct.totalEquipment = jsonObject[code + 'EQUIPO'] === undefined ? 0 : jsonObject[code + 'EQUIPO'];
            loadUnitProduct.totalInstallation = jsonObject[code + 'INSTALACION'] === undefined ? 0 : jsonObject[code + 'INSTALACION'];
            loadUnitProduct.totalSubscription = jsonObject[code + 'ABONO'] === undefined ? 0 : jsonObject[code + 'ABONO'];
            loadUnitProduct.totalActivation = jsonObject[code + 'ACTIVACION'] === undefined ? 0 : jsonObject[code + 'ACTIVACION'];
          }

          loadUnitProduct.total = loadUnitProduct.totalActivation + loadUnitProduct.totalSubscription;
        } catch (error) {
          loadUnitProduct.code = product.code;
          loadUnitProduct.product = product;
          loadUnitProduct.maxPrice = 0;
          loadUnitProduct.minPrice = 0;
          loadUnitProduct.totalEquipment = 0;
          loadUnitProduct.totalInstallation = 0;
          loadUnitProduct.totalSubscription = 0;
          loadUnitProduct.totalActivation = 0;
           this._toastrService.error(`Validar el precio del producto ${product.concept} para el tipo de negocio de la orden.`, 'Error')
        }

        loadUnitProduct.productId = product.id;

        unitProdcts.push(loadUnitProduct);
      }
    });
  }

  downloadFile(url, filename) {
    this._toastrService.info('Descargando plantilla.')
    this.http.get(url, { responseType: 'blob' }).subscribe(res => {
      FileSaver.saveAs(res, filename);
    });
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    //console.log(excelBuffer);
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  //Metodos para el registro de productos registrados dentro de la orden de trabajo.

  getWoProductStorehouseByWordorderId(workorderId: string) {
    return this.afs.collection(this.woUnitProductsStorehouseCollectionName, ref => ref.where('workorderId', '==', workorderId)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as WoProductStorehouse;
        data.id = a.payload.doc.id;
        if (data.calculateDate) {
          data.calculateDate = Utils.timestampToDate(data.calculateDate);
        }
        return { ...data };
      }))
    );
  }

  getWoProductStorehouseByProductId(productId: string) {
    return this.afs.collection(this.woUnitProductsStorehouseCollectionName, ref => ref.where('productId', '==', productId)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as WoProductStorehouse;
        data.id = a.payload.doc.id;
        if (data.calculateDate) {
          data.calculateDate = Utils.timestampToDate(data.calculateDate);
        }
        return { ...data };
      }))
    );
  }

  getWoProductStorehouseByProductIdnDate(productId: string, startDate: Date, endDate: Date) {
    return this.afs.collection(this.woUnitProductsStorehouseCollectionName, ref =>
      ref.where('productId', '==', productId).orderBy('calculateDate').startAt(startDate).endAt(endDate)).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          //console.log(a.payload.doc.id);
          const data = a.payload.doc.data() as WoProductStorehouse;
          data.id = a.payload.doc.id;
          if (data.calculateDate) {
            data.calculateDate = Utils.timestampToDate(data.calculateDate);
          }
          return { ...data };
        }))
      );
  }


  upsertProductsStoreHouse(listStoreHouse: Array<WoProductStorehouse>) {
    listStoreHouse.forEach(storehouse => {
      console.log(storehouse);
      this.upserProductStorehouse(storehouse);
    });
  }

  upserProductStorehouse(woProductStorehouse: WoProductStorehouse) {
    if (!woProductStorehouse.id) {
      woProductStorehouse.id = this.afs.createId();
    }
    return this.afs.collection(this.woUnitProductsStorehouseCollectionName).doc(woProductStorehouse.id).set({
      productId: woProductStorehouse.productId,
      otNumber: woProductStorehouse.otNumber,
      workorderId: woProductStorehouse.workorderId,
      quantity: woProductStorehouse.quantity,
      status: woProductStorehouse.status,
      calculateDate: woProductStorehouse.calculateDate,
      concept: woProductStorehouse.concept,
    }, { merge: true });
  }

  deleteAllStorehouseByWorkOrder(productStorehouseDocs: Array<WoProductStorehouse>) {
    productStorehouseDocs.forEach(productStorehouseDoc => {
      this.afs.collection(this.woUnitProductsStorehouseCollectionName).doc(productStorehouseDoc.id).delete();
    });
  }

  integrateClientIdField(workorders: Array<Workorder>) {
    workorders.forEach(wo => {
      console.log(wo);
      this.afs.collection(this.workOrderCollectionName).doc(wo.id).set({
        clientId: wo.clientId ? wo.clientId : wo.client.id
      }, { merge: true });
    });
  }

  fixPayment(wokrOrderId: string, startPayDate: Date, startPayment: number, endPayment: number, totalPayments: number, period: number) {
    let nextDate = startPayDate;
    console.log(nextDate);
    let pays = new Array<PaymentWorkorder>();
    for (let index = startPayment; index <= endPayment; index++) {
      let pay = new PaymentWorkorder();
      nextDate = moment(nextDate).add(period, 'month').toDate();
      pay.first = false;
      pay.payDate = nextDate;
      pay.additionals = 0;
      pay.invoice = 0;
      pay.paymentActivation = 0;
      pay.paymentAnnualPay = 0;
      pay.paymentEquipment = 0;
      pay.paymentInstall = 0;
      pay.percentageActivation = 0;
      pay.percentageAnnualPay = 0;
      pay.percentageEquipment = 0;
      pay.percentageInstall = 0;
      pay.receiptNumber = index + '/' + totalPayments;
      pay.status = 'PENDIENTE';
      pay.total = 0;
      pay.totalTaxes = 0;
      pay.workorderId = wokrOrderId;
      pays.push(pay);
    }
    console.log(pays);
    return pays;
  }
}


