import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { ProductPortioninvoice } from '../../model/product-portioninvoice';

@Injectable({
  providedIn: 'root'
})
export class WorkorderProductpartialService {

  woProductPartials = 'wo-productpartials';

  constructor(private _afs:AngularFirestore) { }


  getPartialsByWorkorderId(workOrderId:string){
    return this._afs.collection<ProductPortioninvoice>(this.woProductPartials, ref=>ref.where('workOrderId','==',workOrderId).orderBy('unitVIN','desc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as ProductPortioninvoice;
        if(!data.id){
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getPartialsByPaymentId(paymentId){
    return this._afs.collection<ProductPortioninvoice>(this.woProductPartials, ref=>ref.where('paymentId','==',paymentId).orderBy('unitVIN','desc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as ProductPortioninvoice;
        if(!data.id){
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  //Carga masiva de desgloses.
  batchUpsertPartials(partials:Array<ProductPortioninvoice>){
    try {
      let batchPartials = this._afs.firestore.batch();
      partials.forEach(partial => {
        //console.log(partial);
        if(!partial.id){
          partial.id = this._afs.createId();
        }
        let ref = this._afs.collection(this.woProductPartials).doc(partial.id).ref;

        ref.set(
          {
            'workOrderId':partial.workOrderId,
            'otNumber':partial.otNumber,
            'paymentId':partial.paymentId,
            'unitId':partial.unitId,
            'unitProductId':partial.unitProductId,
            'concept':partial.concept,
            'productId':partial.productId,
            'unitVIN':partial.unitVIN,
            'productCVE':partial.productCVE,
            'productName':partial.productName,
            'productUnitSAT':partial.productUnitSAT,
            'productAccountable':partial.productAccountable,
            'productCodeSAT':partial.productCodeSAT,
            'first':partial.first?partial.first:false,
            'amountEq':partial.amountEq,
            'amountInstall':partial.amountInstall,
            'amountService':partial.amountService,
            'amountActivation':partial.amountActivation,
            'amount':partial.amount,        
            'status':partial.status?partial.status:'PENDIENTE',
            'unitInstalled':partial.unitInstalled??true,
            'productInstalled':partial.productInstalled??false
          }, {merge:true});
      });
      batchPartials.commit();
    } catch (error) {
      throw error;
    }
  }

  upsertPartials(partials:Array<ProductPortioninvoice>){
    console.log('DESGLOSES >>>>> '+partials.length);
    if(partials.length>0){
    partials.forEach(partial => {
      //console.log(partial);
      if(!partial.id){
        partial.id = this._afs.createId();
      }
      this.upsertPartial(partial);
    });
  }
  }

  upsertPartial(partial:ProductPortioninvoice){
    return this._afs.collection(this.woProductPartials).doc(partial.id).set(
      {
        'workOrderId':partial.workOrderId,
        'otNumber':partial.otNumber,
        'paymentId':partial.paymentId,
        'unitId':partial.unitId,
        'concept':partial.concept,
        'productId':partial.productId,
        'unitVIN':partial.unitVIN,
        'productCVE':partial.productCVE,
        'productName':partial.productName,
        'productUnitSAT':partial.productUnitSAT,
        'productAccountable':partial.productAccountable,
        'productCodeSAT':partial.productCodeSAT,
        'first':partial.first?partial.first:false,
        'amountEq':partial.amountEq,
        'amountInstall':partial.amountInstall,
        'amountService':partial.amountService,
        'amountActivation':partial.amountActivation,
        'amount':partial.amount,        
        'status':partial.status?partial.status:'PENDIENTE',
        'unitInstalled':partial.unitInstalled??true,
        'productInstalled':partial.productInstalled??false,
      }, {merge:true}).catch(console.log);
  }

  deletePartials(partials:Array<ProductPortioninvoice>):Promise<Boolean>{
   return new Promise((resolve, reject)=>{
    partials.forEach(partial => {
      if(partial.id){
        this.deletePartial(partial.id!);
      }
    })
    resolve(true);
   });
  }

  deletePartial(partialId:string){
    this._afs.collection(this.woProductPartials).doc(partialId).delete();
  }
}