import { PaymentType } from "./payment-type";

export class PaymentConfig {

    businesType:string='';
    label:string='';
    payForms:Array<PaymentType> = new Array();


    constructor(businesType:string, label:string, payForms:Array<PaymentType>){
        this.businesType = businesType;
        this.label = label;
        this.payForms = payForms;
    }

}
