import { Component, OnInit, signal } from '@angular/core';
import { NotificationsUsecase } from '../../../use-cases/shared/notifications-usecase';
import { environment } from '../../../../environments/environment';
import { Notification } from '../../../model/notification';
import moment from 'moment';

@Component({
  selector: 'app-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.scss']
})
export class ListNotificationsComponent implements OnInit {

  notifications:Array<Notification>;
  company: string = 'sitrack';

  startPeriod:Date;
  endPeriod:Date;

  loading = signal(false);

  itemsPerPage:number = 100;
  

  constructor(private _notificationsUsecase:NotificationsUsecase) { 
    this.notifications = new Array<Notification>();
    this.company = localStorage.getItem(environment.sessionWorkCompany);
  }

  ngOnInit(): void {
    this.getByCompany();
  }

  async getByCompany(){
    this.company = localStorage.getItem(environment.sessionWorkCompany);
    this.notifications = new Array<Notification>();
    this.loading.set(true);
    this.startPeriod = moment(this.startPeriod).startOf("week").toDate();
    this.startPeriod.setHours(0);
    this.startPeriod.setMinutes(0);
    this.startPeriod.setSeconds(0);
    this.endPeriod = moment(this.endPeriod).endOf("week").toDate();
    this.endPeriod.setHours(23);
    this.endPeriod.setMinutes(59);
    this.endPeriod.setSeconds(59);
    /*this._notificationsUsecase.getByCompanyAndPeriod(this.company, this.startPeriod, this.endPeriod).then(notifications=>{
      console.log(notifications);
      this.notifications = notifications.sort((noteA, noteB)=>noteB.recordDate<noteA.recordDate?-1:1);
      this.loading.set(false);
    });*/
    this.loadNotificationPage();
  }

  loadNotificationPage(){
    this._notificationsUsecase.getByCompanyAndPeriodPages(this.company, this.startPeriod, this.endPeriod,this.itemsPerPage,new Notification()).then(notifications=>{
      console.log(notifications);
      this.notifications = notifications.sort((noteA, noteB)=>noteB.recordDate<noteA.recordDate?-1:1);
      this.loading.set(false);
    });
  }

  loadMore(){
    let lastNotification = this.notifications[this.notifications.length-1];
    console.log(lastNotification);
    this._notificationsUsecase.getByCompanyAndPeriodPages(this.company, this.startPeriod, this.endPeriod,this.itemsPerPage,lastNotification).then(notifications=>{
      console.log(notifications);
      this.notifications = notifications.sort((noteA, noteB)=>noteB.recordDate<noteA.recordDate?-1:1);
      this.loading.set(false);
    });
  }

}
