import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { User } from '../model/user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  error:any;
  usersCollectionName = 'users';

  user:any;
  sessionUser?:string;
  sessionUserPosition?:string;
  sessionEmployeeSAE?:string;

  constructor(public auth: AngularFireAuth, private _afs:AngularFirestore) { }

  login(email:string, password:string){
    return this.auth.signInWithEmailAndPassword(email,password); 
  }

  logout(){
     return this.auth.signOut();
  }

  sendRecoveryEmail(email:string){
    this.auth.sendPasswordResetEmail(email);
  }

  getUserInfoById(uid:string){
   return this._afs.doc<User>(this.usersCollectionName+'/'+uid).valueChanges();
  }

  getUsers(){
   return this._afs.collection(this.usersCollectionName,ref=>ref.orderBy('position','asc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as User;
        data.id = a.payload.doc.id;
        if(!data.id){
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getUsersByCompany(company:string){
    return this._afs.collection(this.usersCollectionName,ref=>ref.where('company','==',company).orderBy('name','asc')).snapshotChanges().pipe(
       map(actions => actions.map(a => {
         //console.log(a.payload.doc.id);
         const data = a.payload.doc.data() as User;
         data.id = a.payload.doc.id;
         if(!data.id){
           data.id = a.payload.doc.id;
         }
         return { ...data };
       }))
     );
   }

  getUsersByPosition(position:string){
    return this._afs.collection(this.usersCollectionName,ref=>ref.where('position','==',position)).snapshotChanges().pipe(
       map(actions => actions.map(a => {
         //console.log(a.payload.doc.id);
         const data = a.payload.doc.data() as User;
         data.id = a.payload.doc.id;
         if(!data.id){
           data.id = a.payload.doc.id;
         }
         return { ...data };
       }))
     );
   }

   getUsersByPositions(positions:Array<string>){
    return this._afs.collection(this.usersCollectionName,ref=>ref.where('position','in',positions).orderBy('position','asc')).snapshotChanges().pipe(
       map(actions => actions.map(a => {
         //console.log(a.payload.doc.id);
         const data = a.payload.doc.data() as User;
         data.id = a.payload.doc.id;
         if(!data.id){
           data.id = a.payload.doc.id;
         }
         return { ...data };
       }))
     );
   }

   async newUser(user:string, password:string){
    return this.auth.createUserWithEmailAndPassword(user, password);
   }

   async upsert(user:User){
    return this._afs.collection(this.usersCollectionName).doc(user.id).set({
      email:user.email,
      name:user.name,
      accessLevel:user.accessLevel,
      position:user.position,
      company:user.company
    },{merge:true});
   }
}
